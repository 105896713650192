import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import LogoGrid from '../logos/LogoGrid';
import './VerticalTabsSection.css';

const VerticalTabsSection = ({ tabs, id, backgroundColor, color }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tabs[0]?.id || '');

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <section id={id} className="pt-5 pb-5" style={{ backgroundColor, color }}>
      <div className="container desktop-container ">
        <h2 className="vertical-tabs-section-title mb-2 mb-lg-4">
          How It Works
        </h2>
        <div className="vertical-tabs-container">
          {/* Tabs List */}
          <div className="vertical-tabs-list">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`vertical-tabs-button ${
                  activeTab === tab.id ? 'vertical-tabs-button-active' : ''
                }`}
              >
                <span className="vertical-tabs-pretitle">
                  {tab.tabPretitle}
                </span>
                <strong className="vertical-tabs-title">
                  <tab.badgeIcon className="vertical-tabs-title-icon ml-4 me-1" />
                  {tab.tabTitle}
                </strong>
              </button>
            ))}
          </div>
          {/* Tab Content */}
          <div className="vertical-tabs-content">
            {tabs.map(
              (tab) =>
                tab.id === activeTab && (
                  <React.Fragment key={tab.id}>
                    {/* First Column: Stacked Text */}
                    <div className="vertical-tabs-details">
                      <Badge
                        style={{ width: 'fit-content' }}
                        className="my-2 vertical-tabs-badge vertical-tabs-badge-black"
                      >
                        {tab.tabPretitle}
                      </Badge>
                      <h2 className="vertical-tabs-header">{tab.header}</h2>
                      <p className="vertical-tabs-description">
                        {tab.description}
                      </p>
                      {tab.logos && <LogoGrid logos={tab.logos} />}
                      <button
                        className="btn btn-ig-blue cta-btn vertical-tabs-cta-button my-4"
                        onClick={() => navigate('/demo-signup')}
                      >
                        Get a Demo
                      </button>
                    </div>
                    {/* Second Column: Image */}
                    <div className="vertical-tabs-image-column">
                      <img
                        src={tab.imageSrc}
                        alt={tab.header}
                        className="vertical-tabs-image"
                      />
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerticalTabsSection;
