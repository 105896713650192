import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation for accessing navigation state
import './ThankYouPage.css'; // Ensure that you have styling for card and shadow here
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';

const ApplicationNotFoundPage = () => {
  const location = useLocation(); // Access location object
  const companyLogoURL =
    location.state?.companyLogoURL ||
    'https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png'; // Fallback to default logo

  return (
    <div className="thank-you-container">
      {/* TODO: Replace hardcoded Signal Security company logo*/}
      <div className="card-container">
        <JobCompanyLogo src={companyLogoURL} alt="Signal Logo" />
        <h1 className="thank-you-header">Application Not Found</h1>
        <p className="thank-you-subtitle">
          We are unable to locate an application for the link you clicked.
        </p>
        <p className="thank-you-subtitle">
          Double-check your text messages, and make sure to select the newest
          link that we texted you.
        </p>
      </div>
    </div>
  );
};

export default ApplicationNotFoundPage;
