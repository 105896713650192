import React from 'react';
import BannerSection from '../components/sections/BannerSection';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';
import HeroSection from '../components/sections/HeroSection';
import FeatureCardsSection from '../components/sections/FeatureCardsSection';
import {
  ChatDots,
  Stars,
  ArrowCounterclockwise,
  Magic,
  Funnel,
  PersonRaisedHand,
  Soundwave,
  CardChecklist,
} from 'react-bootstrap-icons';

const FeaturesPage = () => {
  const atsLogos = (logos) => {
    return logos.map((logo) =>
      logo.alt === 'Jazz HR logo'
        ? {
            ...logo,
            src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/jazz-hr-logo-square-white.png',
          }
        : logo
    );
  };

  return (
    <div>
      <div className="homepage-container">
        {/* Hero section */}
        <HeroSection
          id="hero1"
          background="radial-gradient(circle, rgba(0, 249, 235, 0.1) 25%, white 80%)"
          color="#333"
          title={`Your ultimate co\u2011pilot for efficient hiring`}
          subtitle={`Discover InstaHear’s innovative features designed to transform\\nhiring, automate 24/7 interviews, and help you\\nsecure top talent faster than ever.`}
          mobileSubtitle="Discover InstaHear’s innovative features designed to transform hiring, automate 24/7 interviews, and help you secure top talent faster than ever."
        />

        {/* Feature Cards - Engagement */}
        <FeatureCardsSection
          id="engagement-feature-cards"
          fontColor="#fff"
          backgroundGradient="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          sectionTitle="Automated Features to Engage Your Applicants"
          subtitle="Struggling to keep in touch with applicants while they're most interested in your job? We've got that covered."
          demoListItems={[
            'Save time with follow-ups sent over 36 hours',
            'Customize messages to match your brand voice',
            'Use pre-made templates for seamless outreach',
            'Auto-reply to frequently asked questions',
            'Reconnect with past applicants effortlessly',
          ]}
          featureCards={[
            {
              title: 'Automated SMS and Email',
              icon: ChatDots,
              description:
                'Each applicant is texted and emailed immediately after they apply to your jobs. Follow-up messages are sent over 36 hours.',
            },
            {
              title: 'Customizable templates',
              icon: Stars,
              description:
                "Use your company's brand voice in each SMS and Email. Or use our ready-to-go templates for all your automated outreach.",
            },
            {
              title: 'Re-engagement campaign tools',
              icon: ArrowCounterclockwise,
              description:
                'Easily reach out to past applicants who never interviewed or are re-elligible to interview for your jobs.',
            },
          ]}
          //buttonTitle="Get a Demo"
          //buttonHref="/demo-signup"
          badgeText="Engagement"
          reverseRow={false}
        />

        {/* CTA Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="#fff"
          color="#000"
          title="Ready to transform your hiring?"
          subtitle="Activate InstaHear on your ATS to experience the hiring advantages with instant AI interviews."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-blue cta-btn my-4"
        />

        {/* Feature Cards - Interview */}
        <FeatureCardsSection
          id="interview-feature-cards"
          fontColor="#fff"
          backgroundGradient="#000"
          sectionTitle="Advanced, Human-Like Phone Interview Features"
          subtitle="Our experience is designed to feel so human, applicants won't even realize they’re speaking with AI."
          demoListItems={[
            'Conduct individual interviews simultaneously',
            'Capture top-talent with 24/7 phone interviews',
            `Eliminate scheduling with on-demand interviews`,
            'Filter out applicants before the phone interview',
            'Maintain consistency with structured call scripts',
          ]}
          featureCards={[
            {
              title: 'Pre-interview knockout questions',
              icon: Funnel,
              description:
                'Identify qualified applicants early by verifying essential job requirements before the phone screen.',
            },
            {
              title: 'Customizable interview call scripts',
              icon: Magic,
              description: `Set a custom intro for each call and dynamically ask follow-up questions based on previous responses.`,
            },
            {
              title: `Comprehensive Company and Job Q&A`,
              icon: PersonRaisedHand,
              description:
                'Empower our AI assistant to answer applicant questions about your Company or Job using a knowledge base you provide.',
            },
          ]}
          //buttonTitle="Get a Demo"
          //buttonHref="/demo-signup"
          badgeText="Interviews"
          reverseRow={true}
        />

        {/* ATS logos Section */}
        <LogoSection
          id="integration-section"
          backgroundColor="#6f00f9"
          color="#fff"
          title="INTEGRATES WITH 100+ ATS PLATFORMS"
          logos={atsLogos(logos)}
        />

        {/* Feature Cards - Automation */}
        <FeatureCardsSection
          id="automation-feature-cards"
          fontColor="#fff"
          backgroundGradient="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          sectionTitle="ATS Automations That Keep Your Applicants Updated"
          subtitle="InstaHear’s ATS automations update applicant statuses and interview outcomes directly in your ATS, ensuring your data stays accurate and up-to-date."
          demoListItems={[
            'Save time from manually updating your ATS',
            'Customize how your applicants are updated',
            'No more switching between multiple platforms',
            'Keep all of your data directly in one place',
            'Easily activate new jobs and call scripts',
          ]}
          featureCards={[
            {
              title: 'AI call summaries',
              icon: Stars,
              description:
                'Each applicant phone interview is summarized and the results are updated automatically in your ATS.',
            },
            {
              title: 'AI call transcripts',
              icon: CardChecklist,
              description:
                'Review the full call transcript for detailed insights into how applicants answered your questions.',
            },
            {
              title: 'AI call recordings',
              icon: Soundwave,
              description: `Listen to the full call recording to assess tone, mannerisms, and soft skills in your applicant’s responses.`,
            },
          ]}
          //buttonTitle="Get a Demo"
          //buttonHref="/demo-signup"
          badgeText="Automation"
          reverseRow={false}
        />

        {/* CTA Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="#eef"
          color="black"
          title={`Don't be the last to use AI for hiring`}
          subtitle="Screen applicants 75% faster and secure top talent ahead of your competitors."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-blue cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default FeaturesPage;
