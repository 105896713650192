import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import TermsCheckbox from '../components/jobs/TermsCheckbox';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const AdminDemoPage = () => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobCompanyLogo, setJobCompanyLogo] = useState(
    `https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png`
  );
  const [demoJobs, setDemoJobs] = useState([]);
  const [engageSmsEmail, setEngageSmsEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Submit Application');
  const [isChecked, setIsChecked] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);

  // Modal State
  const [showModal, setShowModal] = useState(false);
  const [interviewNowUrl, setInterviewNowUrl] = useState('');
  const [engageStatus, setEngageStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/admin/demo-jobs`, {
          credentials: 'include',
        });
        const data = await response.json();
        setDemoJobs(data.demoJobs || []);
      } catch (error) {
        console.error('Error fetching demo jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (engageSmsEmail && !isChecked) {
      setShowCheckboxError(true);
      return;
    }

    setIsSubmitting(true);

    const formData = {
      channel: 'Demo',
      lastName,
      firstName,
      phone,
      email,
      jobId,
      engageSmsEmail,
    };

    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/admin/application/demo`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setInterviewNowUrl(data.interviewNowUrl);
        setEngageStatus(data.engageSmsEmail);
        setShowModal(true);
        setHasSubmitted(true);
        setButtonText(`Application ${engageSmsEmail ? 'Sent' : 'Created'}!`);
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container my-5">
      <JobCompanyLogo src={jobCompanyLogo} alt="Company Logo" />

      <div className="form-container bg-white px-4 py-5 rounded shadow">
        <p className="pb-3 text-center subtitle-demo-form">
          Fill out your Applicant's info below to generate an
          <br /> Interview Now link.
        </p>

        <form onSubmit={handleSubmit}>
          {/* First Name and Last Name */}
          <div className="row">
            <div className="form-group col-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Phone and Email */}
          <div className="row">
            <div className="form-group col-6 mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                maxLength="10"
              />
            </div>
            <div className="form-group col-6 mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          {/* Job Position Autocomplete */}
          <div className="form-group mb-4">
            <label htmlFor="jobId" className="form-label">
              Job Position:
            </label>
            <Select
              className="small-font"
              styles={{
                container: (base) => ({ ...base, width: '100%' }),
              }}
              options={demoJobs.map((job) => ({
                value: job.jobId,
                label: `${job.jobTitle} (${job.jobCompanyName})`,
                logo: job.jobCompanyLogo,
              }))}
              onChange={(selectedOption) => {
                setJobId(selectedOption?.value || '');
                setJobCompanyLogo(
                  selectedOption?.logo ||
                    `https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png`
                );
              }}
              placeholder="Search and select a Job Position"
              isClearable
            />
          </div>

          {/* Engage via SMS and Email */}
          {phone && (
            <div className="form-group mb-4">
              <label className="form-label">Engagement Settings:</label>
              <div>
                <input
                  type="checkbox"
                  checked={engageSmsEmail}
                  onChange={(e) => setEngageSmsEmail(e.target.checked)}
                />
                <span className="ms-2">
                  Send Interview Now link via SMS and Email
                </span>
              </div>
            </div>
          )}

          {/* Terms of Service */}
          {engageSmsEmail && (
            <TermsCheckbox
              mode="admin-demo"
              isChecked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                setShowCheckboxError(false);
              }}
              showError={showCheckboxError}
            />
          )}

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-3"
            disabled={isSubmitting || hasSubmitted}
          >
            {buttonText}
          </button>
        </form>
      </div>

      {/* Information Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100 fw-bold">
            Application Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Interview Now Demo Link:</p>
          <div className="d-flex justify-content-center align-items-center">
            <a
              href={interviewNowUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2"
            >
              {interviewNowUrl}
            </a>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(interviewNowUrl);
                alert('Interview Now link copied to clipboard!');
              }}
              className="ms-2"
            >
              📋
            </Button>
          </div>
          <br />
          <br />
          {engageStatus ? (
            <p>Applicant has been engaged via SMS and Email.</p>
          ) : (
            <p>🙋 Note, Applicant has not been engaged via SMS and Email.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <footer className="text-center mt-5">
        2025 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default AdminDemoPage;
