import React from 'react';
import './LogoSection.css';

const LogoSection = ({ id, backgroundColor, color, title, logos }) => {
  return (
    <section
      id={id}
      className="logo-section pt-5 pb-5"
      style={{ backgroundColor, color }}
    >
      <div className="container desktop-container">
        {/* Centered Title */}
        <h2 className="logo-section-title text-center">{title}</h2>

        {/* Logos Row */}
        <div className="logo-row">
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className="logo-image"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default LogoSection;
