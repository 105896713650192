import {
  ArrowRepeat,
  ChatLeftDots,
  FunnelFill,
  TelephoneOutboundFill,
  Soundwave,
} from 'react-bootstrap-icons';

export const howItWorksTabs = [
  {
    id: 'tab-1',
    badgeIcon: ArrowRepeat,
    badgeText: 'ATS automations',
    header: 'Fetches your new job applicants',
    description: `InstaHear's ATS automations fetch all of your new applicants within minutes of applying. We support connections to all major applicant tracking systems.`,
    tabPretitle: 'Step 1',
    tabTitle: 'Get Applicants',
    fontColor: 'black',
    imageSrc:
      'https://storage.googleapis.com/instahear-react-images/how-it-works-images/how-it-works-section-step1-iphone.png',
    ctaButton: {
      title: 'Get Demo',
      navigateTo: '/demo-signup',
    },
  },
  {
    id: 'tab-2',
    badgeIcon: ChatLeftDots,
    badgeText: 'Instant Engagement',
    header: 'Engages your new applicants',
    description: `InstaHear sends your applicants an SMS and Email message with a URL to begin their interview anytime that's convenient for them.`,
    tabPretitle: 'Step 2',
    tabTitle: 'Engage Applicants',
    fontColor: 'black',
    imageSrc:
      'https://storage.googleapis.com/instahear-react-images/how-it-works-images/how-it-work-section-step2-iphone',
    ctaButton: {
      title: 'Get Demo',
      navigateTo: '/demo-signup',
    },
  },
  {
    id: 'tab-3',
    badgeIcon: FunnelFill,
    badgeText: 'Knockout Questions',
    header: 'Filters out unqualified applicants',
    description: `Filter out applicants who are unable or unwilling to perform key job functions, lack required qualifications, or exhibit other red flags.`,
    tabPretitle: 'Step 3',
    tabTitle: 'Pre-Screen Applicants',
    fontColor: 'black',
    imageSrc:
      'https://storage.googleapis.com/instahear-react-images/how-it-works-images/how-it-works-section-step3-iphone.png',
    ctaButton: {
      title: 'Get Demo',
      navigateTo: '/demo-signup',
    },
  },
  {
    id: 'tab-4',
    badgeIcon: TelephoneOutboundFill,
    badgeText: 'AI Phone Interviews',
    header: 'Calls your applicants for a phone interview',
    description: `After completing the knockout questions, our AI Interview Assistant takes over, conducting a seamless phone interview with your applicants.`,
    tabPretitle: 'Step 4',
    tabTitle: 'Interview Applicants',
    fontColor: 'black',
    imageSrc:
      'https://storage.googleapis.com/instahear-react-images/how-it-works-images/how-it-works-section-step4-new-iphone.png',
    ctaButton: {
      title: 'Get Demo',
      navigateTo: '/demo-signup',
    },
  },
  {
    id: 'tab-5',
    badgeIcon: Soundwave,
    badgeText: 'AI Call Summary & Recording',
    header: 'Auto updates your ATS with interview results',
    description: `InstaHear’s ATS automations update applicant statuses and interview outcomes directly in your ATS, ensuring your data stays accurate and up-to-date.`,
    tabPretitle: 'Step 5',
    tabTitle: 'Update Applicants in ATS',
    fontColor: 'black',
    imageSrc:
      'https://storage.googleapis.com/instahear-react-images/how-it-works-images/how-it-works-section-step5-iphone-new.png',
    imageHeight: 'auto',
    ctaButton: {
      title: 'Get Demo',
      navigateTo: '/demo-signup',
    },
  },
];
