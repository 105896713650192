import './index.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router

// Render application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {' '}
      {/* Wrap the application with HelmetProvider */}
      <Router>
        {' '}
        {/* Wrap the application with Router */}
        <App />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);
