import React, { useEffect, useState } from 'react';
import { Table, Badge, Button, Form, Modal } from 'react-bootstrap';
import InfoTooltip from './InfoTooltip';
import { EyeFill, Building } from 'react-bootstrap-icons';
import AppPageTitle from '../components/app/AppPageTitle';
import './CompanyAssessmentsPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const CompanyUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/users`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        setUsers(data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    });
    setTimezoneAbbreviation(timeString.split(' ')[2] || '');
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filteredAndSortedUsers = [...users]
    .filter((user) => {
      const fullname = `${user.firstName} ${user.lastName}`.toLowerCase();
      return (
        user.firstName.toLowerCase().includes(searchQuery) ||
        user.lastName.toLowerCase().includes(searchQuery) ||
        fullname.includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery)
      );
    })
    .sort((a, b) => {
      if (sortColumn === 'createdAt') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortColumn(column);
      setSortOrder('DESC');
    }
  };

  const handleEyeClick = (user) => {
    setModalContent(user.userRoles);
    setShowModal(true);
  };

  const renderModalContent = (userRoles) => {
    if (!userRoles || userRoles.length === 0) {
      return <p>No roles assigned.</p>;
    }

    return userRoles.map((userRole, index) => (
      <div key={index} style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <strong>{userRole.role}</strong>

          <InfoTooltip
            id={`tooltip-${index}`}
            message={userRole.roleDescription || 'No description available'}
          />
        </div>

        <div style={{ margin: '10px 0', marginLeft: '20px' }}>
          {userRole.role === 'Company Manager' ? null : userRole.jobGroups
              .length > 0 ? (
            userRole.jobGroups.map((jobGroup, i) => (
              <p
                key={i}
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Building style={{ fontSize: '1rem', color: 'inherit' }} />
                {jobGroup.name} ({jobGroup.alternateId})
              </p>
            ))
          ) : (
            <p>No job groups assigned.</p>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="wide-container small-font">
      <div>
        <AppPageTitle title="Users" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <Form.Control
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: '300px',
                display: 'inline-block',
                marginRight: '15px',
              }}
            />
          </div>
          <Button
            variant="primary"
            size="sm"
            className="fw-bold"
            onClick={() => console.log('implement')}
            disabled={true}
          >
            Create User
          </Button>
        </div>
      </div>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th
              onClick={() => handleSort('createdAt')}
              style={{ cursor: 'pointer' }}
            >
              Created At ({timezoneAbbreviation}){' '}
              {sortColumn === 'createdAt' && (sortOrder === 'ASC' ? '↑' : '↓')}
            </th>
            <th className="company-assessments-page-td-width">First Name</th>
            <th className="company-assessments-page-td-width">Last Name</th>
            <th className="company-assessments-page-td-width">Email</th>
            <th>Status</th>
            <th>Permissions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedUsers.map((user) => (
            <tr key={`${user.id}-${user.firstName}-${user.lastName}`}>
              <td>{formatDate(user.createdAt)}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>
                {user.isActive ? (
                  <Badge bg={'success'}>Enabled</Badge>
                ) : (
                  <Badge bg={'secondary'}>Disabled</Badge>
                )}
              </td>
              <td>
                <EyeFill
                  className="text-dark"
                  style={{ fontSize: '1rem', cursor: 'pointer' }}
                  onClick={() => handleEyeClick(user)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalContent(modalContent)}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyUsersPage;
