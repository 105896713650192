import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Container } from 'react-bootstrap';
import { PersonCircle, Shuffle } from 'react-bootstrap-icons'; // Correctly importing from react-bootstrap-icons
import AdminChangeCompaniesModal from './AdminChangeCompaniesModal'; // Import your modal component
import './AppNavbar.css';

const PilotNavbar = ({
  companyName,
  sourceId,
  userEmail,
  homeLink,
  isAdmin,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);

  const BACKEND_API_URL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://api.instahear.ai';

  const handleShuffleClick = async () => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/admin/companies`, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setCompanies(data.companies || []);
        setShowModal(true);
      } else {
        console.error('Failed to fetch companies');
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-4 pilotNavbar">
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to={homeLink}
            className="d-flex align-items-center"
          >
            <span>
              {companyName} {sourceId && <span>({sourceId})</span>}
            </span>
            {isAdmin && (
              <Shuffle
                size={20}
                className="ms-2"
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={handleShuffleClick}
                title="Change Companies"
              />
            )}
          </Navbar.Brand>

          {/* Toggle for the hamburger menu */}
          <Navbar.Toggle aria-controls="navbar-content" />

          <Navbar.Collapse id="navbar-content">
            <Nav className="ms-auto d-flex align-items-center">
              {isAdmin && (
                <Dropdown className="me-4">
                  <Dropdown.Toggle
                    as="span"
                    className="d-flex align-items-center"
                    style={{ cursor: 'pointer', color: '#fff' }}
                  >
                    Admin
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={'/admin/demo'}>
                      Create Demo
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to={'/admin/applications'}>
                      Applications
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Nav.Link as={Link} to={'/jobs'}>
                Jobs
              </Nav.Link>
              <Nav.Link as={Link} to={'/call-scripts'}>
                Call Scripts
              </Nav.Link>
              <Nav.Link as={Link} to={'/sessions'}>
                Sessions
              </Nav.Link>
              <Nav.Link as={Link} to={'/analytics'}>
                Analytics
              </Nav.Link>
              <Dropdown align="end">
                <Dropdown.Toggle
                  as="span"
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer', color: '#fff' }}
                >
                  <PersonCircle size={24} className="me-2" />
                  {userEmail}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to={'/users'}>
                    Team Members
                  </Dropdown.Item>
                  {isAdmin && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item as={Link} to={'/admin/settings/company'}>
                        Company Settings
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to={'/logout'}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modal for changing companies */}
      {isAdmin && (
        <AdminChangeCompaniesModal
          companies={companies}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default PilotNavbar;
