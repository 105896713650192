import React from 'react';
import './FeatureCardsSection.css';
import { Check } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const FeatureCardsSection = ({
  id,
  backgroundGradient = 'radial-gradient(circle, #00f9eb 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)',
  fontColor = '#000',
  sectionTitle = 'Feature Section Title',
  subtitle = 'Short descriptive subtitle goes here.',
  demoListItems = [
    'Item 1 description',
    'Item 2 description',
    'Item 3 description',
  ],
  featureCards = [
    {
      title: 'Unified Data Dashboard',
      description:
        'Consolidate all your data into one intuitive dashboard. With real-time analytics, customizable widgets, and interactive reports, visualize metrics.',
    },
    {
      title: 'Feature Two Title',
      description: 'Description for the second feature.',
    },
  ],
  buttonHref,
  buttonTitle,
  badgeText,
  reverseRow = false,
}) => {
  return (
    <section
      id={id}
      className="content-section"
      style={{ background: backgroundGradient, color: fontColor }}
    >
      <div className="container desktop-container">
        <div
          className={`row gx-5 prevent-overflow ${
            reverseRow ? 'flex-row-reverse' : ''
          }`}
        >
          {/* Column 1 */}
          <div className="col-lg-6 col-12 mx-lg-0 feature-section-column">
            {badgeText && (
              <span class="badge badge-pill feature-cards-badge-transparent mb-4">
                {badgeText}
              </span>
            )}
            <h2 className="feature-cards-section-title">{sectionTitle}</h2>
            <p className="feature-cards-subtitle">{subtitle}</p>
            <ul className="feature-cards-feature-list">
              {demoListItems.map((item, index) => (
                <li key={index}>
                  <Check className="feature-cards-check-icon" />
                  {item}
                </li>
              ))}
            </ul>
            {buttonTitle && buttonHref && (
              <Link to={buttonHref} className="btn btn-ig-blue cta-btn my-4">
                {buttonTitle}
              </Link>
            )}
          </div>

          {/* Column 2 */}
          <div className="col-lg-6 col-12 feature-section-column">
            {featureCards.map((card, index) => (
              <div key={index} className="feature-cards-card">
                <div className="feature-cards-card-item-top">
                  <div className="feature-cards-feature-item-icon-wrap">
                    <card.icon size={24} />
                  </div>
                  <div className="feature-cards-item-title-wrap">
                    <div className="feature-cards-item-title">{card.title}</div>
                  </div>
                </div>
                <div className="feature-cards-item-bottom">
                  <div className="feature-cards-item-text-wrap">
                    <p className="feature-cards-item-text">
                      {card.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCardsSection;
