export const logos = [
  {
    src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/talent-reef-logo-abbreviation.png',
    alt: 'TalentReef logo',
  },
  {
    src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/icims-logo.png',
    alt: 'ICIMS logo',
  },
  {
    src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/smart-recruiters-logo.png',
    alt: 'Smart Recruiters logo',
  },
  {
    src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/jazz-hr-logo-square.png',
    alt: 'Jazz HR logo',
  },
  {
    src: 'https://storage.googleapis.com/instahear-react-images/ats-automation-logos/work-day-logo.png',
    alt: 'Workday logo',
  },
];
