import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce'; // Install lodash.debounce using `npm install lodash.debounce`
import Select from 'react-select';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  Card,
  Container,
  Form,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppPageTitle from '../components/app/AppPageTitle';
import RequiredAsterisk from './RequiredAsterisk';
import InfoTooltip from './InfoTooltip';
import { EyeFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CompanyJobDetailsPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}
// Define debounce delay (e.g., 500ms)
const DEBOUNCE_DELAY = 500;

const CompanyJobDetailPage = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [job, setJob] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [jobGroups, setJobGroups] = useState([]);
  const [jobGroupsWithPublicNames, setJobGroupsWithPublicNames] = useState([]);
  const [activeTab, setActiveTab] = useState('details');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const fetchDraftInitialized = useRef(false); // To track if `fetchOrCreateDraft` has been called
  const debounceSaveRef = useRef(null); // Ref for the debounced save function
  const [isAdmin] = useState(() => localStorage.getItem('isAdmin') === 'true');

  useEffect(() => {
    if (job) {
      // Call fetchAssessments with isPrivateDemo parameter once the job is populated
      const fetchAssessmentsWithJobDetails = async () => {
        try {
          const response = await fetch(
            `${BACKEND_API_URL}/api/assessments?isPublished=true&isPrivateDemo=${job.isPrivateDemo}`,
            {
              credentials: 'include',
            }
          );

          if (!response.ok) {
            throw new Error('Failed to fetch assessments');
          }

          const data = await response.json();
          setAssessments(
            data.assessments.map(
              (assessment) =>
                ({
                  value: assessment.id,
                  label: assessment.name,
                } || [])
            )
          );
        } catch (error) {
          console.error('Error fetching assessments:', error);
        }
      };

      fetchAssessmentsWithJobDetails();
    }
  }, [job]); // Runs whenever the `job` state changes

  const fetchJobGroups = async () => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/job-groups`, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch job groups');
      }

      const data = await response.json();
      setJobGroups(data.jobGroups);
      setJobGroupsWithPublicNames(data.jobGroupsWithPublicNames);
    } catch (error) {
      console.error('Error fetching jobGroups:', error);
    }
  };

  useEffect(() => {
    // Initialize the debounced save function
    debounceSaveRef.current = debounce(async (fieldData) => {
      if (job?.id) {
        try {
          const response = await fetch(
            `${BACKEND_API_URL}/api/jobs/draft/${job.id}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(fieldData),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Error autosaving draft:', errorData);
            return;
          }

          const result = await response.json();
          setJob((prevJob) => ({
            ...prevJob,
            unSavedChanges: result.job.unSavedChanges,
            updatedAt: result.job.updatedAt,
          }));
          console.log('Draft saved successfully');
        } catch (error) {
          console.error('Error in autosaveDraft:', error);
        }
      }
    }, DEBOUNCE_DELAY);

    return () => {
      // Cleanup debounce on component unmount
      debounceSaveRef.current?.cancel();
    };
  }, [job?.id]);

  const handleFieldChange = (updates) => {
    // Update state immediately for responsive UI
    setJob((prevJob) => ({
      ...prevJob,
      ...updates, // Merge multiple updates
    }));

    // Debounce save
    debounceSaveRef.current(updates);
  };

  // Function to get publicName by id
  const getPublicNameById = (id) => {
    const jobGroup = jobGroupsWithPublicNames.find((group) => group.id === id);
    return jobGroup ? jobGroup.publicName : null; // Return null if no match is found
  };

  useEffect(() => {
    if (!fetchDraftInitialized.current && jobId) {
      fetchDraftInitialized.current = true;
      fetchOrCreateDraft(jobId);
      fetchJobGroups();
    }
  }, [jobId]);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const publishJob = async () => {
    setPublishing(true);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/jobs/publish/${job.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(job), // Include job data in the request body
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      const data = await response.json();

      toast.success('Job published successfully!', {
        position: 'top-right',
        autoClose: 5000,
      });

      // Optional: Perform any additional actions after successful publish
      console.log(`Published job data: ${data.id}`);
      setJob((prevJob) => ({
        ...prevJob,
        parentJob: {
          publishedAt: new Date(),
        },
        unSavedChanges: data.job.unSavedChanges,
        updatedAt: data.job.updatedAt,
        isActive: data.job.isActive,
      }));
    } catch (error) {
      console.error('Error publishing job:', error);
      toast.error('An unexpected error occurred while publishing the job.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } finally {
      setPublishing(false);
    }
  };

  const fetchOrCreateDraft = async (jobId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/jobs/draft/${jobId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch or create draft job');
      }

      const data = await response.json();
      setJob(data.job);
    } catch (error) {
      console.error('Error fetching or creating draft:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    navigate(`${location.pathname}?tab=${tabKey}`, { replace: true });
  };

  const handleDelete = async () => {
    try {
      // Confirm the deletion with the user
      const confirmation = window.confirm(
        'Are you sure you want to delete this job? This action cannot be undone.'
      );
      if (!confirmation) return;

      // Call the DELETE API
      const response = await fetch(`${BACKEND_API_URL}/api/jobs/${jobId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Job deleted successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/jobs');
    } catch (error) {
      console.error('Error deleting job:', error);
      toast.error('An unexpected error occurred while deleting the job.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  const handleArchive = async () => {
    try {
      // Confirm the archive action with the user
      const confirmation = window.confirm(
        'Are you sure you want to archive this job? This action can be undone by restoring the job.'
      );
      if (!confirmation) return;

      // Call the POST API to archive the job
      const response = await fetch(
        `${BACKEND_API_URL}/api/jobs/archive/${jobId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Job archived successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/jobs');
    } catch (error) {
      console.error('Error archiving job:', error);
      toast.error('An unexpected error occurred while archiving the job.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  const handleClone = async () => {
    try {
      // Confirm the archive action with the user
      const confirmation = window.confirm(
        'Are you sure you want to clone this job?'
      );
      if (!confirmation) return;

      // Call the POST API to archive the job
      const response = await fetch(
        `${BACKEND_API_URL}/api/jobs/clone/${jobId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Job cloned successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/jobs');
    } catch (error) {
      console.error('Error cloning job:', error);
      toast.error('An unexpected error occurred while cloning the job.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const publishedDate = new Date(timestamp);
    const diffInMs = now - publishedDate;
    const diffInMinutes = Math.floor(diffInMs / 60000);

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes === 1) return '1 minute ago';
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours === 1) return '1 hour ago';
    if (diffInHours < 24) return `${diffInHours} hours ago`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays === 1) return '1 day ago';
    return `${diffInDays} days ago`;
  };

  // Render loading spinner or job details form
  if (loading) return <div>Loading...</div>;
  if (!job) return null;

  return (
    <Container
      className="mt-4"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <ToastContainer />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <AppPageTitle title={job.title} />

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {job.parentJob?.publishedAt && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '30px',
                }}
              >
                <Form.Check
                  type="switch"
                  id="job-status-toggle"
                  checked={job.isActive}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleFieldChange({ isActive: isChecked }); // Call handleFieldChange
                  }}
                  label=""
                  className="me-2"
                />
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {job.isActive ? 'Accepting Apps' : 'Not Accepting Apps'}
                  <InfoTooltip
                    message={'Enable job to accept new applications.'}
                  />
                </span>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="primary"
                size="sm"
                className="fw-bold"
                disabled={publishing || !job.unSavedChanges?.hasChanges}
                onClick={publishJob}
                style={{ marginRight: '10px' }}
              >
                Publish Job
              </Button>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {/* Dropdown menu button */}
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="actionDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    &#8942; {/* Vertical ellipsis icon */}
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="actionDropdown"
                  >
                    {job.canArchive && (
                      <>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleArchive}
                          >
                            Archive
                          </button>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </>
                    )}
                    <li>
                      <button className="dropdown-item" onClick={handleClone}>
                        Clone
                      </button>
                    </li>
                    {job.canDelete && (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginTop: '20px',
              marginRight: '10px',
              fontSize: '.8rem',
              color: 'gray',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!job.parentJob?.isDraft &&
                job.unSavedChanges?.hasChanges &&
                job.unSavedChanges.fields && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {`This Job has unpublished changes in the following fields:`}
                        <ul style={{ margin: 0, padding: '5px 0 0 15px' }}>
                          {job.unSavedChanges.fields.map((field, index) => (
                            <li key={index} style={{ fontSize: '0.85rem' }}>
                              {field}
                            </li>
                          ))}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <span
                      className="text-warning"
                      style={{
                        display: 'inline-block',
                        width: '9px',
                        height: '9px',
                        backgroundColor: 'currentColor',
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    ></span>
                  </OverlayTrigger>
                )}
              <div style={{ fontWeight: 'bold' }}>Last Published:</div>
            </div>
            <div>
              {job.parentJob?.publishedAt
                ? formatTimeAgo(job.parentJob?.publishedAt)
                : 'Never'}
            </div>
            <div style={{ marginTop: '8px', fontWeight: 'bold' }}>
              Last Updated:
            </div>
            <div>{job.updatedAt ? formatTimeAgo(job.updatedAt) : 'Never'}</div>
          </div>
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        className="mb-3 tab-font"
        id="job-details-tabs"
      >
        {/* Details Tab */}
        <Tab
          eventKey="details"
          title={
            <span
              style={{
                fontWeight: activeTab === 'details' ? 'bold' : 'normal',
              }}
            >
              Details
            </span>
          }
        >
          <Card className="mb-4 w-100">
            <Card.Body className="p-4">
              <Form>
                <Form.Group className="mb-4 small-font">
                  <Form.Label>
                    Job Title
                    <RequiredAsterisk />
                    <InfoTooltip message="Position title, required for AI conversation." />
                  </Form.Label>
                  <Form.Control
                    className="small-font"
                    type="text"
                    value={job.title || ''}
                    style={{ width: '350px' }}
                    onChange={(e) =>
                      handleFieldChange({ title: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3 small-font">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                    <InfoTooltip message="Provide overview details about this position. Required to enhance AI-driven answers to applicant questions." />
                  </Form.Label>
                  <Form.Control
                    className="small-font"
                    as="textarea"
                    rows={8}
                    value={job.description || ''}
                    onChange={(e) =>
                      handleFieldChange({ description: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4 small-font">
                  <Form.Label>
                    Benefits
                    <RequiredAsterisk />
                    <InfoTooltip message="Provide details about the benefits offered. Required to enhance AI-driven answers to applicant questions." />
                  </Form.Label>
                  <Form.Control
                    className="small-font"
                    as="textarea"
                    rows={6}
                    value={job.benefits || ''}
                    onChange={(e) =>
                      handleFieldChange({ benefits: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4 small-font">
                  <Form.Label>
                    Responsibilities
                    <RequiredAsterisk />
                    <InfoTooltip message="Provide details about the position responsibilities. Required to enhance AI-driven answers to applicant questions." />
                  </Form.Label>
                  <Form.Control
                    className="small-font"
                    as="textarea"
                    rows={6}
                    value={job.responsibilities || ''}
                    onChange={(e) =>
                      handleFieldChange({ responsibilities: e.target.value })
                    }
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Tab>

        {/* Automation Tab */}
        <Tab
          eventKey="automation"
          title={
            <span
              style={{
                fontWeight: activeTab === 'automation' ? 'bold' : 'normal',
              }}
            >
              Automation
            </span>
          }
        >
          <Card className="mb-4" id="ats-settings">
            <Card.Body className="p-4">
              <Form.Group controlId="sourceId" className="small-font">
                <Form.Label>
                  Job Id
                  <RequiredAsterisk />
                  <InfoTooltip message="ATS job requisition id. Required for the automation to engage and update applicants." />
                </Form.Label>
                <Form.Control
                  className="small-font"
                  type="text"
                  value={job.sourceId || ''}
                  onChange={(e) =>
                    handleFieldChange({ sourceId: e.target.value })
                  }
                  style={{ width: '250px' }}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Tab>

        {/* Call Script Tab */}
        <Tab
          eventKey="callScript"
          title={
            <span
              style={{
                fontWeight: activeTab === 'callScript' ? 'bold' : 'normal',
              }}
            >
              Call Script
            </span>
          }
        >
          <Card className="mb-4 w-100">
            <Card.Body className="p-4">
              <Form.Group className="mb-4 small-font">
                <Form.Label>
                  Job Group
                  <RequiredAsterisk />
                  <InfoTooltip message="Groups jobs by location and limits access to authorized team members within the Job Group." />
                </Form.Label>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Select
                    className="small-font"
                    styles={{
                      container: (base) => ({ ...base, width: '350px' }),
                    }}
                    options={jobGroups}
                    value={
                      jobGroups.find(
                        (option) => option.value === job.jobGroupId
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      handleFieldChange({
                        jobGroupId: selectedOption?.value || null,
                        companyName: selectedOption?.value
                          ? getPublicNameById(selectedOption?.value)
                          : null,
                      });
                    }}
                    placeholder="Select a Job Group"
                    isClearable
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 small-font">
                <Form.Label>
                  Call Script
                  <RequiredAsterisk />
                  <InfoTooltip message="The call script contains Knockout Questions, Interview Questions and the AI criteria to determine whether applicants Pass or Fail." />
                </Form.Label>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <Select
                    className="small-font"
                    styles={{
                      container: (base) => ({ ...base, width: '450px' }),
                    }}
                    options={assessments}
                    value={
                      assessments.find(
                        (option) => option.value === job.assessmentId
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      handleFieldChange({
                        assessmentId: selectedOption
                          ? selectedOption.value
                          : null,
                      });
                    }}
                    placeholder="Select an active Call Script"
                    isClearable
                  />
                  {job.assessmentId && (
                    <a
                      href={`/call-scripts/${job.assessmentId}/edit?tab=knockoutQuestions`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Preview Call Script"
                    >
                      <EyeFill size={20} />
                    </a>
                  )}
                </div>
                <div style={{ marginTop: '7px' }}>
                  <a
                    href="/call-scripts"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create a New Call Script
                  </a>
                </div>
              </Form.Group>
              <Form.Group className="mb-4 small-font">
                <Form.Label>
                  Company Name
                  <RequiredAsterisk />
                  <InfoTooltip message="Option to override the default Company name. Required for AI conversation." />
                </Form.Label>
                <Form.Control
                  className="small-font"
                  type="text"
                  value={job.companyName || ''}
                  style={{ width: '350px' }}
                  onChange={(e) =>
                    handleFieldChange({ companyName: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 small-font">
                <Form.Label>
                  Introduction
                  <RequiredAsterisk />
                  <InfoTooltip message="Option to override the default Company introduction. Required for the AI interviewer to provide the applicant with some background information about either the Company or the Position." />
                </Form.Label>
                <Form.Control
                  className="small-font"
                  as="textarea"
                  rows={3}
                  value={job.introduction || ''}
                  onChange={(e) =>
                    handleFieldChange({ introduction: e.target.value })
                  }
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Tab>

        {/* Post-Interview Messages Tab */}
        <Tab
          eventKey="postInterviewMessages"
          title={
            <span
              style={{
                fontWeight:
                  activeTab === 'postInterviewMessages' ? 'bold' : 'normal',
              }}
            >
              Post-Interview Messages
            </span>
          }
        >
          <Card className="mb-4 w-100">
            <Card.Body className="p-4">
              <Form.Group className="mb-4 small-font">
                <Form.Label>
                  Passed Interview Message
                  <RequiredAsterisk />
                  <InfoTooltip message="Content for SMS and email notifications sent to applicants who Passed the phone interview." />
                </Form.Label>
                <Form.Control
                  className="small-font"
                  as="textarea"
                  rows={6}
                  value={job.smsPassContent || ''}
                  onChange={(e) =>
                    handleFieldChange({ smsPassContent: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-4 small-font">
                <Form.Label>
                  Failed Interview Message
                  <RequiredAsterisk />
                  <InfoTooltip message="Content for SMS and email notifications sent to applicants who Failed the phone interview." />
                </Form.Label>
                <Form.Control
                  className="small-font"
                  as="textarea"
                  rows={6}
                  value={job.smsFailContent || ''}
                  onChange={(e) =>
                    handleFieldChange({ smsFailContent: e.target.value })
                  }
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Tab>

        {/* Admin Tab */}
        {isAdmin && (
          <Tab
            eventKey="admin"
            title={
              <span
                style={{
                  fontWeight: activeTab === 'admin' ? 'bold' : 'normal',
                }}
              >
                Admin
              </span>
            }
          >
            <Card className="mb-4 w-100">
              <Card.Body className="p-4">
                <Form.Group className="mb-4 small-font">
                  <Form.Label>
                    Company Logo URL
                    <InfoTooltip message="Option to override the default Company logo URL. This image is displayed to applicants on the Interview Now page." />
                  </Form.Label>
                  <Form.Control
                    className="small-font"
                    type="text"
                    value={job.companyLogoURL || ''}
                    style={{ width: '800px' }}
                    onChange={(e) =>
                      handleFieldChange({ companyLogoURL: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-4 small-font">
                  <Form.Label>
                    Is Private Demo?
                    <InfoTooltip message="Enable this option to allow the Job to be used for creating demo Applications." />
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="job-status-toggle"
                    checked={job.isPrivateDemo}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      handleFieldChange({ isPrivateDemo: isChecked }); // Call handleFieldChange
                    }}
                    label=""
                    className="me-2"
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Tab>
        )}
      </Tabs>
    </Container>
  );
};

export default CompanyJobDetailPage;
