import React, { useEffect, useState } from 'react';
import {
  Table,
  Badge,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
  Modal,
  Card,
} from 'react-bootstrap';
import {
  PersonLinesFill,
  PencilSquare,
  FileBarGraphFill,
  PieChartFill,
  Stars,
  CardList,
} from 'react-bootstrap-icons';
import AppPageTitle from '../components/app/AppPageTitle';
import { Link, useNavigate } from 'react-router-dom';
import './CompanyJobsPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const CompanyJobsPage = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [newDraftJobLoading, setNewDraftJobLoading] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    acceptingApplications: true,
    notAcceptingApplications: true,
    draft: true,
    archived: false,
  });
  const [showModal, setShowModal] = useState(false);

  // Fetch jobs from the API once on component mount
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/jobs`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch jobs');
        }

        const data = await response.json();
        setJobs(data.jobs); // Store the full list of jobs
        setFilteredJobs(data.jobs); // Initialize the filtered list
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    });
    setTimezoneAbbreviation(timeString.split(' ')[2] || '');
  }, []);

  // Format date helper function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  // Handle search and filtering changes
  useEffect(() => {
    const filterJobs = () => {
      const filtered = jobs.filter((job) => {
        const matchesSearch =
          job.companyName.toLowerCase().includes(searchQuery) ||
          job.jobGroupName?.toLowerCase().includes(searchQuery) ||
          job.title.toLowerCase().includes(searchQuery) ||
          (job.sourceId && job.sourceId.toLowerCase().includes(searchQuery));

        const matchesFilter =
          (filters.acceptingApplications && job.status.state === 'Enabled') ||
          (filters.notAcceptingApplications &&
            job.status.state === 'Disabled') ||
          (filters.draft && job.status.state === 'Draft') ||
          (filters.archived && job.status.state === 'Archived');

        return matchesSearch && matchesFilter;
      });

      setFilteredJobs(filtered);
    };

    filterJobs();
  }, [jobs, searchQuery, filters]); // Only rerun when these dependencies change

  // Handle search input changes
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // Handle filter toggles
  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  // Sort the filtered jobs
  const sortedJobs = [...filteredJobs].sort((a, b) => {
    if (sortOrder === 'DESC') {
      if (!a.publishedAt) return -1;
      if (!b.publishedAt) return 1;
    } else {
      if (!a.publishedAt) return 1;
      if (!b.publishedAt) return -1;
    }

    const dateA = new Date(a.publishedAt);
    const dateB = new Date(b.publishedAt);
    return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };

  const createDraftJob = async () => {
    setNewDraftJobLoading(true);
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/jobs/draft`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to create draft job');
      }

      const data = await response.json();
      setNewDraftJobLoading(false);
      navigate(`/jobs/${data.job.id}/edit`);
    } catch (error) {
      console.error('Error creating draft job:', error);
    } finally {
      setNewDraftJobLoading(false);
    }
  };

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <div className="wide-container small-font">
      <div>
        <AppPageTitle title="Jobs" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <Form.Control
              type="text"
              placeholder="Search jobs..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: '300px',
                display: 'inline-block',
                marginRight: '15px',
              }}
            />
            <Form.Check
              inline
              label="Accepting Apps"
              checked={filters.acceptingApplications}
              onChange={() => handleFilterChange('acceptingApplications')}
            />
            <Form.Check
              inline
              label="Not Accepting Apps"
              checked={filters.notAcceptingApplications}
              onChange={() => handleFilterChange('notAcceptingApplications')}
            />
            <Form.Check
              inline
              label="Draft"
              checked={filters.draft}
              onChange={() => handleFilterChange('draft')}
            />
            <Form.Check
              inline
              label="Archived"
              checked={filters.archived}
              onChange={() => handleFilterChange('archived')}
            />
          </div>
          <Button
            variant="primary"
            size="sm"
            className="fw-bold"
            onClick={handleModalOpen}
            disabled={newDraftJobLoading}
          >
            Create Job
          </Button>
        </div>
      </div>
      <Table striped bordered hover className="mt-4">
        <thead className="company-jobs-page-thead">
          <tr>
            <th onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
              Last Published At ({timezoneAbbreviation}){' '}
              {sortOrder === 'ASC' ? '↑' : '↓'}
            </th>
            <th className="company-jobs-page-td-width">Job Group</th>
            <th className="company-jobs-page-td-width">Job Title</th>
            <th className="company-jobs-page-td-width">Call Script</th>
            <th>Job Id</th>
            <th># of Sessions</th>
            <th>Analytics</th>
            <th>Accepting Apps?</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {sortedJobs.map((job) => (
            <tr key={job.id || job.sourceId || `${job.title}-${job.updatedAt}`}>
              <td>
                {job.publishedAt ? (
                  formatDate(job.publishedAt)
                ) : (
                  <Badge bg={'info'}>{job.status.state}</Badge>
                )}
                {job.status.state !== 'Draft' &&
                  job.unSavedChanges?.hasChanges && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {`This Job has unpublished changes in the following fields:`}
                          <ul style={{ margin: 0, padding: '5px 0 0 15px' }}>
                            {job.unSavedChanges.fields.map((field, index) => (
                              <li key={index} style={{ fontSize: '0.85rem' }}>
                                {field}
                              </li>
                            ))}
                          </ul>
                        </Tooltip>
                      }
                    >
                      <span
                        className="text-warning"
                        style={{
                          display: 'inline-block',
                          width: '9px',
                          height: '9px',
                          backgroundColor: 'currentColor',
                          borderRadius: '50%',
                          marginLeft: '8px',
                        }}
                      ></span>
                    </OverlayTrigger>
                  )}
              </td>
              <td className="company-jobs-page-td-width">{job.jobGroupName}</td>
              <td className="company-jobs-page-td-width">{job.title}</td>
              <td className="company-jobs-page-td-width">
                <Link
                  to={`/call-scripts/${job.assessment?.id}/edit`}
                  style={{
                    textDecoration: 'underline',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {job.assessment?.name}
                </Link>
              </td>
              <td>{job.sourceId}</td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {(job.status === 'Enabled' || job.sessionsCount > 0) && (
                  <Link
                    to={`/sessions/jobs/${job.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ marginRight: '8px' }}>
                      {job.sessionsCount}
                    </span>{' '}
                    {/* Add space between count and icon */}
                    <PersonLinesFill
                      className="text-dark"
                      style={{ fontSize: '1rem' }}
                    />{' '}
                    {/* Larger icon */}
                  </Link>
                )}
                {job.status.state !== 'Enabled' && <span>{''}</span>}
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {(job.status === 'Enabled' || job.sessionsCount > 0) && (
                  <Link
                    to={`/analytics/jobs/${job.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* Add space between count and icon */}
                    <FileBarGraphFill
                      className="text-dark"
                      style={{ fontSize: '1rem' }}
                    />
                    <PieChartFill
                      className="text-dark"
                      style={{ fontSize: '1rem', marginLeft: '8px' }}
                    />
                    {/* Larger icon */}
                  </Link>
                )}
                {job.status.state !== 'Enabled' && <span>{''}</span>}
              </td>
              <td>
                {['Enabled', 'Disabled', 'Draft'].includes(job.status.state) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {job.status?.acceptingApplicationsTooltip}
                      </Tooltip>
                    }
                  >
                    <Badge
                      bg={
                        job.status.state === 'Enabled'
                          ? 'success'
                          : job.status.state === 'Disabled'
                          ? 'secondary'
                          : 'info'
                      }
                      style={{ cursor: 'pointer' }} // Add pointer cursor for better UX
                    >
                      {job.status.acceptingApplicationsColumn}
                    </Badge>
                  </OverlayTrigger>
                ) : (
                  <Badge
                    bg={
                      job.status.state === 'Archived'
                        ? 'dark'
                        : job.status.state === 'Draft'
                        ? 'info'
                        : 'secondary' // Fallback for unknown statuses
                    }
                  >
                    {job.status.state}
                  </Badge>
                )}
              </td>
              <td>
                <Link
                  to={`/jobs/${job.id}/edit`}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* Add space between count and icon */}
                  <PencilSquare
                    className="text-dark"
                    style={{ fontSize: '1rem' }}
                  />{' '}
                  {/* Larger icon */}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select a Job Creation Path</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Card
            className="text-center"
            style={{
              width: '48%',
              border: '1px solid #dee2e6',
              padding: '20px',
            }}
          >
            <Stars
              size={40}
              className="mb-2 text-primary mx-auto"
              color="#6f00f9"
            />
            <Card.Body>
              <Card.Title>AI Creator</Card.Title>
              <Button
                variant="primary"
                className="mt-3 fw-bold"
                onClick={() => navigate('/jobs/ai-creator')}
              >
                Create Job
              </Button>
            </Card.Body>
          </Card>
          <Card
            className="text-center"
            style={{
              width: '48%',
              border: '1px solid #dee2e6',
              padding: '20px',
            }}
          >
            <CardList
              size={40}
              className="mb-2 text-primary mx-auto"
              color="black"
            />
            <Card.Body>
              <Card.Title>Manual Creator</Card.Title>
              <Button
                variant="primary"
                className="mt-3 fw-bold"
                onClick={createDraftJob}
              >
                Create Job
              </Button>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompanyJobsPage;
