import React from 'react';
import './BannerSection.css';

const BannerSection = ({
  id,
  backgroundColor,
  color,
  title,
  subtitle,
  buttonTitle,
  buttonHref,
  buttonClass,
}) => {
  return (
    <section
      id={id}
      className="banner-section pt-5 pb-5"
      style={{ backgroundColor, color }}
    >
      <div className="container desktop-container">
        {/* Title */}
        {title && <h1 className="banner-title">{title}</h1>}

        {/* Subtitle */}
        {subtitle && <p className="banner-subtitle">{subtitle}</p>}

        {/* Button */}
        {buttonTitle && buttonHref && (
          <a href={buttonHref} className={`banner-button ${buttonClass}`}>
            {buttonTitle}
          </a>
        )}
      </div>
    </section>
  );
};

export default BannerSection;
