import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const VerifyAuthTokenPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      // Extract token and redirectUrl from URL query params
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const redirectUrl = searchParams.get('redirectUrl');
      let BACKEND_API_URL = 'https://api.instahear.ai';

      if (process.env.REACT_APP_ENV === 'development') {
        BACKEND_API_URL = 'http://localhost:3001';
      }

      if (!token || !redirectUrl) {
        console.error('Token or redirect URL not provided');
        return navigate('/login');
      }

      try {
        const response = await fetch(
          `${BACKEND_API_URL}/api/verify?token=${token}&redirectUrl=${redirectUrl}`,
          {
            credentials: 'include', // Allows server to set cookies
            method: 'GET',
          }
        );

        if (response.ok) {
          const data = await response.json(); // Parse the JSON response

          // Save the userEmail from the API response to localStorage
          if (data.userEmail) {
            localStorage.setItem('userEmail', data.userEmail);
          }

          if (data.homeLink) {
            localStorage.setItem('homeLink', data.homeLink);
          }

          if (data.companyName) {
            localStorage.setItem('companyName', data.companyName);
          }

          if (data.companyId) {
            localStorage.setItem('companyId', data.companyId);
          }

          if (data.isAdmin) {
            localStorage.setItem('isAdmin', data.isAdmin);
          }

          // If the server verifies the token, navigate to the redirect URL
          navigate(redirectUrl);
        } else {
          // If the token is invalid, redirect to the login page
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        navigate('/login');
      }
    };

    verifyToken();
  }, [location, navigate]);

  return (
    <div className="container mt-5">
      <h3>Verifying your login...</h3>
    </div>
  );
};

export default VerifyAuthTokenPage;
