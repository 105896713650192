import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import TermsCheckbox from '../jobs/TermsCheckbox';
import JobCompanyLogo from '../jobs/JobCompanyLogo';

const BACKEND_API_URL =
  process.env.REACT_APP_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://api.instahear.ai';

const DemoForm = ({
  logoSrc,
  formTitle = "Fill out your info below, and we'll text you a link to begin your phone interview.",
  fetchJobsUrl = `${BACKEND_API_URL}/api/demo-jobs`,
  submitFormUrl = `${BACKEND_API_URL}/api/application/demo`,
  initialFirstName = '',
  initialLastName = '',
  initialPhone = '',
  initialEmail = '',
  initialJobId = '',
  jobIds = [],
  initialAcceptTerms = false,
}) => {
  const [lastName, setLastName] = useState(initialLastName);
  const [firstName, setFirstName] = useState(initialFirstName);
  const [phone, setPhone] = useState(initialPhone);
  const [email, setEmail] = useState(initialEmail);
  const [jobId, setJobId] = useState(initialJobId);
  const [jobOptions, setJobOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Start Demo');
  const [isChecked, setIsChecked] = useState(initialAcceptTerms);
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on load
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(
          `${fetchJobsUrl}?jobIds=${jobIds.join(';')}`
        );
        const data = await response.json();
        setJobOptions(data.demoJobs || []);
      } catch (error) {
        console.error('Error fetching job options:', error);
      }
    };

    fetchJobs();
  }, [fetchJobsUrl, jobIds]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      setShowCheckboxError(true);
      return;
    }

    setIsSubmitting(true);
    const formData = {
      channel: 'Demo',
      lastName,
      firstName,
      phone,
      email,
      jobId,
    };

    try {
      const response = await fetch(submitFormUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setHasSubmitted(true);
        setButtonText('Demo Created!');
        setShowModal(true); // Show the modal on success
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container my-5">
      <JobCompanyLogo src={logoSrc} alt="Company Logo" />
      <div className="form-container bg-white px-4 py-5 rounded shadow">
        <p className="pb-3 text-center subtitle-demo-form">{formTitle}</p>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                maxLength="10"
                required
              />
            </div>
            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="jobId" className="form-label">
              Job Position:
            </label>
            <select
              id="jobId"
              className="form-select"
              value={jobId}
              onChange={(e) => setJobId(e.target.value)}
              required
            >
              <option value="">Select a Job</option>
              {jobOptions.map((job) => (
                <option key={job.jobId} value={job.jobId}>
                  {job.jobTitle}
                </option>
              ))}
            </select>
          </div>

          <TermsCheckbox
            isChecked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
              setShowCheckboxError(false);
            }}
            showError={showCheckboxError}
          />

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-3"
            disabled={isSubmitting || hasSubmitted}
          >
            {buttonText}
          </button>
        </form>
      </div>

      {/* Success Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Demo Created! 🎉</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Our AI assistant just sent you a message. Check your text or email to
          get started!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DemoForm;
