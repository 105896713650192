import React from 'react';
import './DemoSignupPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const DemoPageSignupSuccessPage = () => {
  return (
    <div className="demo-signup-page demo-signup-background">
      {/* Navbar */}
      <nav className="demo-navbar container-fluid">
        <a href="/" className="demo-navbar-link">
          <img
            src="https://storage.googleapis.com/instahear-react-images/demo-signup-page-images/instahear-white-blue-logo.png"
            alt="InstaHear Logo"
            className="demo-navbar-logo"
          />
        </a>
      </nav>

      <div className="demo-content-container">
        {/* Page Content */}
        <div className="demo-card-container">
          <div className="demo-card">
            <div className="demo-card-header">
              <h3>Demo Request Received 🎉</h3>
              <p style={{ whiteSpace: 'pre-line' }} className="my-4">
                {`Thank you for submitting the demo request form!\n\nA member of our Sales Team team will be in touch with you shortly.`}
              </p>
            </div>
            <a href="/" className="btn btn-dark demo-submit-btn">
              Return to homepage
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoPageSignupSuccessPage;
