import React from 'react';
import './HeroSection.css';

const HeroSection = ({
  id,
  background,
  color,
  title,
  subtitle,
  mobileTitle,
  mobileSubtitle,
  subtitleCustomStyling = { marginBottom: '2rem' },
}) => {
  const sectionStyle = {
    background,
    color,
  };

  const formatTextWithLineBreaks = (text, delimiter = '\\n') => {
    return text.split(delimiter).map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <section
      id={id}
      className="hero-section d-flex align-items-center text-center"
      style={sectionStyle}
    >
      <div className="container desktop-container">
        <div className="row justify-content-center">
          <div className="col-12">
            {/* Mobile Title */}
            <h1 className="hero-section-h1 hero-section-title mobile">
              {formatTextWithLineBreaks(mobileTitle || title)}
            </h1>
            {/* Desktop Title */}
            <h1 className="hero-section-h1 hero-section-title desktop">
              {formatTextWithLineBreaks(title)}
            </h1>
            {/* Mobile Subtitle */}
            <h2
              className="hero-section-h2 hero-section-subtitle mobile"
              style={subtitleCustomStyling}
            >
              {formatTextWithLineBreaks(mobileSubtitle || subtitle)}
            </h2>
            {/* Desktop Subtitle */}
            <h2
              className="hero-section-h2 hero-section-subtitle desktop"
              style={subtitleCustomStyling}
            >
              {formatTextWithLineBreaks(subtitle)}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
