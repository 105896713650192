import React from 'react';
import './LogoGrid.css'; // Add the styling below into this file

const LogoGrid = ({ logos }) => {
  return (
    <div className="logo-grid u-mt-3">
      {logos.map((logo, index) => (
        <div key={index} className="logo-grid-item u-border-padding">
          {logo.src && (
            <img
              src={logo.src}
              alt={logo.alt}
              className="company-logo u-border_r8"
              loading="lazy"
            />
          )}
          {logo.icon && (
            <logo.icon
              className="company-logo u-border_r8"
              style={{ color: 'black' }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default LogoGrid;
