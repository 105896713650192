import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';

// Get the BACKEND_API_URL based on the environment
export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const SessionDetailPage = () => {
  const { sessionId } = useParams(); // Get the sessionId from the URL
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the session data from the API
    const fetchSessionData = async () => {
      try {
        const response = await fetch(
          `${BACKEND_API_URL}/api/sessions/${sessionId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch session data');
        }
        const data = await response.json();
        setSessionData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchSessionData();
    }
  }, [sessionId]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };
    return new Date(dateTimeString).toLocaleString('en-US', options);
  };

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = ((ms % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
  };

  const formatTranscript = (transcript, applicantName) => {
    return transcript
      .replace(/AI:/g, '<strong>AI:</strong>')
      .replace(
        new RegExp(`${applicantName}:`, 'g'),
        `<strong>${applicantName}:</strong>`
      )
      .replace(/\n/g, '<br /><br />');
  };

  const formatSummaryText = (text) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, (_, matched) => {
        return `<span style="background-color: #333; color: white; border-radius: 50%; padding: 2px 8px; display: inline-flex; align-items: center; justify-content: center; font-size: 0.75em; margin-right: 5px; font-weight: 700;">Q</span><strong>${matched}</strong>`;
      })
      .replace(/\n/g, '<br />');
  };

  const formatInterviewQuestions = (interviewQuestionsSummary) => {
    return interviewQuestionsSummary
      .filter(
        ({ applicantProvidedResponseToQuestion }) =>
          applicantProvidedResponseToQuestion
      ) // Filter out items where applicantProvidedResponseToQuestion is false
      .map(({ questionNumber, question, response, isCorrectResponse }) => {
        // Determine the background color of the question number
        const backgroundColorQuestionNumber = !isCorrectResponse
          ? '#dc3545'
          : '#333';

        // Determine the background color of the question response
        const backgroundColorQuestionResponse = '#eaedff';

        return `
            <div style="margin-bottom: 10px;">
                <span style="background-color: ${backgroundColorQuestionNumber}; color: white; border-radius: 50%; padding: 2px 8px; display: inline-flex; align-items: center; justify-content: center; font-size: 0.75em; margin-right: 5px; font-weight: 700;">
                    Q${questionNumber}
                </span>
                <strong style="font-size: 0.9em; margin-bottom: 1em;font-style: italic;">${question}</strong></br>
                <div style="margin-top: .5rem;border-radius: 20px;background-color:${backgroundColorQuestionResponse};padding: .75rem; margin-left: 1rem;">
                    <span style="font-size: 0.85em;">${response}</span>
                </div></br>
            </div>
          `;
      })
      .join('');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!sessionData) {
    return <div>No session data available.</div>;
  }

  return (
    <div className="container my-5">
      <JobCompanyLogo
        src={`https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png`}
        alt="InstaHear Logo"
      />

      {/* Applicant Info Section */}
      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-3"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Applicant Information
          </h5>
          <p>
            <strong>Name:</strong> {sessionData.firstName}{' '}
            {sessionData.lastName}
          </p>
          <div className="d-flex justify-content-between">
            <p>
              <strong>Phone:</strong> {sessionData.phone}
            </p>
          </div>
        </div>
      </div>

      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-3"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Interview Summary
          </h5>
          <p>
            <strong>Job:</strong> {sessionData.jobTitle}
          </p>
          <p>
            <strong>Start Time:</strong> {formatDateTime(sessionData.startTime)}
          </p>
          <p>
            <strong>Duration:</strong> {formatDuration(sessionData.duration)}
          </p>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="mb-0">
              <strong>Result: </strong>
              <span
                className={`badge ${
                  sessionData.result === 'PASSED'
                    ? 'bg-success'
                    : sessionData.result === 'FAILED'
                    ? 'bg-danger'
                    : 'bg-secondary'
                }`}
                style={{ marginLeft: '8px' }}
              >
                {sessionData.result}
              </span>
            </p>
          </div>
          {sessionData.result === 'INCOMPLETE' &&
            sessionData.callEndedReason && (
              <p>
                <strong>Call Ended Reason:</strong>{' '}
                {sessionData.callEndedReason
                  ? sessionData.callEndedReason
                      .replace(/-/g, ' ') // Replace hyphens with spaces
                      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
                  : 'N/A'}
              </p>
            )}

          {/* Conditionally display failed reasons if result is FAILED */}
          {sessionData.result === 'FAILED' && sessionData.failedReasons && (
            <div className="mt-3">
              <strong>Failed Topics:</strong>
              <ul>
                {sessionData.failedReasons.failedSuccessCriteria?.map(
                  (reason, index) => (
                    <li key={index} style={{ fontWeight: 'normal' }}>
                      {reason}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          {/* Conditionally display failed reasons if result is FAILED */}
          {sessionData.result === 'INCOMPLETE' &&
            sessionData.incompleteReasons && (
              <div className="mt-3">
                <strong>Incomplete Topics:</strong>
                <ul>
                  {sessionData.incompleteReasons.omittedSuccessCriteria?.map(
                    (reason, index) => (
                      <li key={index} style={{ fontWeight: 'normal' }}>
                        {reason}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
        </div>
      </div>

      {/* Call Summary */}
      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-3"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Call Summary
          </h5>
          {sessionData.duration > 0 &&
            sessionData.interviewQuestionsSummary && (
              <div
                dangerouslySetInnerHTML={{
                  __html: formatInterviewQuestions(
                    sessionData.interviewQuestionsSummary
                  ),
                }}
              />
            )}
          {sessionData.duration > 0 &&
            !sessionData.interviewQuestionsSummary && (
              <div
                dangerouslySetInnerHTML={{
                  __html: formatSummaryText(sessionData.summary),
                }}
              />
            )}
          {sessionData.duration === 0 && <p>{sessionData.callEndedReason}</p>}
        </div>
      </div>

      {/* Call Transcript */}
      {sessionData.duration > 0 && (
        <div className="form-container bg-white px-4 rounded shadow my-3">
          <div className="card-body">
            <h5
              className="card-title mb-3"
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              Call Transcript
            </h5>
            <a
              href={sessionData.recordingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="btn fw-bold btn-primary btn-sm mb-4"
            >
              Listen to Recording{' '}
              <i
                className="fas fa-headphones"
                style={{ marginLeft: '5px' }}
              ></i>
            </a>
            <div
              dangerouslySetInnerHTML={{
                __html: formatTranscript(
                  sessionData.transcript,
                  sessionData.firstName
                ),
              }}
            />
          </div>
        </div>
      )}

      <footer className="text-center mt-5">
        2025 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default SessionDetailPage;
