import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function AdminChangeCompaniesModal({
  companies,
  show,
  onClose,
}) {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const BACKEND_API_URL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://api.instahear.ai';

  const handleUpdateCompany = async () => {
    if (!selectedCompany) {
      setError('Please select a company to update.');
      return;
    }

    setError(null);
    setIsSubmitting(true);

    try {
      const response = await fetch(`${BACKEND_API_URL}/api/admin/users`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyId: selectedCompany.value }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('companyName', data.updatedCompanyName);
        localStorage.setItem('companyId', data.updatedCompanyId);
        onClose(); // Close the modal after successful update
        window.location.reload();
      } else {
        setError('Failed to update the company. Please try again.');
      }
    } catch (err) {
      console.error('Error updating company:', err);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const companyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Change Companies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">
          Reassign your Admin User account to a different Company.
        </p>
        <Form.Group>
          <Select
            options={companyOptions}
            onChange={setSelectedCompany}
            placeholder="Start typing to search companies..."
            isClearable
          />
        </Form.Group>
        {error && <p className="text-danger mt-2">{error}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleUpdateCompany}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Updating...' : 'Update Company'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
