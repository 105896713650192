import React from 'react';
import VerticalTabsSection from '../components/sections/VerticalTabsSection';
import BannerSection from '../components/sections/BannerSection';
import { howItWorksTabs } from '../components/data/howItWorksTabs';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';

const HowItWorksPage = () => {
  return (
    <div>
      <div className="homepage-container">
        {/* How It Works Section */}
        <VerticalTabsSection tabs={howItWorksTabs} />

        {/* ATS logos */}
        <LogoSection
          id="integration-section"
          backgroundColor="#eef"
          color="black"
          title="INTEGRATES WITH 100+ ATS PLATFORMS"
          logos={logos}
        />

        {/* Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="black"
          color="white"
          title="Ready to transform your hiring?"
          subtitle="Activate InstaHear on your ATS to experience the hiring advantages with instant AI interviews."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-blue cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default HowItWorksPage;
