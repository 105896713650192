// PilotNavbarWrapper.jsx

import React from 'react';
import PilotNavbar from './AppNavbar';

const PilotNavbarWrapper = ({ children }) => {
  const companyName = localStorage.getItem('companyName') || '';
  const userEmail = localStorage.getItem('userEmail') || '';
  const homeLink = localStorage.getItem('homeLink') || '';
  const isAdmin = localStorage.getItem('isAdmin') || '';

  return (
    <>
      <PilotNavbar
        companyName={companyName}
        sourceId=""
        userEmail={userEmail}
        homeLink={homeLink}
        isAdmin={isAdmin}
      />
      <div>{children}</div>
    </>
  );
};

export default PilotNavbarWrapper;
