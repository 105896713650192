import React from 'react';
import { useNavigate } from 'react-router-dom';
import FeatureBenefitsSection from '../components/sections/FeatureBenefitsSection';
import './WhyInstaHearPage.css';
import VerticalTabsSection from '../components/sections/VerticalTabsSection';
import BannerSection from '../components/sections/BannerSection';
import { howItWorksTabs } from '../components/data/howItWorksTabs';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';
import HeroSection from '../components/sections/HeroSection';

const WhyInstaHearPage = () => {
  const navigate = useNavigate();
  const features = [
    {
      emphasis: 'On-Demand',
      content: 'Capture top talent 24/7 at the height of their interest.',
    },
    {
      emphasis: 'Speed Up Hiring',
      content: 'Fill roles 2x faster and meet tight deadlines.',
    },
    {
      emphasis: 'Stay Consistent',
      content:
        'Ensure a seamless brand experience where no applicant falls through the cracks.',
    },
    {
      emphasis: 'Designed for Humans',
      content:
        "Our interview experience is designed to feel so human, applicants won't even realize they’re speaking with AI.",
    },
    {
      emphasis: 'Don’t Fall Behind',
      content:
        'Without AI, you risk outdated hiring and losing top talent to competitors.',
    },
  ];

  const futureProofFeatures = [
    {
      emphasis: 'Less repitition, more recruiter satisfaction',
      content:
        'Recruiters are happier when they don’t have to repeat themselves. Deliver one, cohesive interview experience for all applicants. and channels.',
    },
    {
      emphasis: '24/7 Applicant engagement, exactly when and where you need it',
      content:
        'Answer common questions from applicants immediately within SMS, Email and during the AI phone screen.',
    },
    {
      emphasis: 'Instant interview outcomes logged automatically.',
      content:
        'InstaHear integrates with 100+ Applicant Tracking Systems, so all interview activity gets logged in real-time.',
    },
    {
      emphasis: 'Less jumping between platform',
      content:
        'Your recruiters continue to work directly within your ATS, only going to InstaHear to tweak knockout and interview questions.',
    },
  ];

  return (
    <div>
      <div className="homepage-container">
        {/* Hero Section */}
        <HeroSection
          id="why-instahear-hero-section"
          background="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          color="#fff"
          title="Engage Applicants Faster\nThan Your Competitors"
          mobileTitle="Engage Applicants Faster Than Your\nCompetitors"
          subtitle="Capture top talent 24/7 at the height of their excitement\nwith on-demand AI phone screens."
          mobileSubtitle="Capture top talent 24/7 at the height of their excitement with\non-demand AI phone screens."
          backgroundImage="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/why-instahear-job-hiring-fair-bg-image.png"
        />
        {/* Can You Relate To This? */}
        <section className="why-instahear-section d-flex align-items-center instahear-white-bg">
          <div className="container desktop-container">
            <div className="row">
              <h3 className="why-instahear-section-h3">Can you relate?</h3>
            </div>
            <div className="row experiences-carousel">
              {/* First Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image1.png"
                  alt="We need to hire a ton of people ASAP"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Hiring managers need me to <b>hire a ton of people ASAP</b>.
                  </p>
                </div>
              </div>

              {/* Second Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image2.png"
                  alt="Our team is burned out from playing phonetag with applicants"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Our team is <b>burned out</b> from <b>playing phonetag</b>{' '}
                    with applicants.
                  </p>
                </div>
              </div>

              {/* Third Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image3.png"
                  alt="Applicants never pick the phone when I call them for an interview"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Applicants <b>never answer the phone</b> when call them for
                    an interview.
                  </p>
                </div>
              </div>

              {/* Fourth Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image4.png"
                  alt="There aren't enough hours to review the daily truck load of new applicants"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Not enough hours to <b>review the daily truck load</b> of
                    new applicants.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4 why-instahear-button-container">
              <button
                className="btn btn-ig-blue cta-btn"
                onClick={() => navigate('/demo-signup')}
              >
                Get a Demo
              </button>
            </div>
          </div>
        </section>
        {/* Hello InstaHear */}
        <FeatureBenefitsSection
          id="why-instahear-features"
          title="Hello InstaHear"
          subtitle={`InstaHear.ai’s AI-powered voice screening engages applicants within minutes—even at 2 a.m.—so your team can focus on the human part of talent acquisition.`}
          carouselItems={[
            {
              number: '89',
              metric: '%',
              textLine1: 'of interviews completed',
              textLine2: 'Same Day',
              textLine3: 'the job applicant was engaged',
            },
            {
              number: '70',
              metric: '%',
              textLine1: 'of interviews completed',
              textLine2: '30 Minutes',
              textLine3: 'after job applicant was engaged',
            },
            {
              number: '49',
              metric: '%',
              textLine1: 'of interviews completed',
              textLine2: 'Outside of Business Hours',
              textLine3: 'Monday to Friday 9am-5pm',
            },
          ]}
          features={features}
          backgroundColor={'#000'}
          color={'#fff'}
        />
        {/* How It Works Section */}
        <VerticalTabsSection tabs={howItWorksTabs} />

        <LogoSection
          id="integration-section"
          backgroundColor="#eef"
          color="black"
          title="INTEGRATES WITH 100+ ATS PLATFORMS"
          logos={logos}
        />

        {/* Future-proof your hiring with AI */}
        <FeatureBenefitsSection
          id="why-instahear-features"
          title={`Future-proof your hiring with AI`}
          subtitle={`What if your team could capture top talent at their peak excitement and fill roles 2x as fast? Here’s how we supercharge talent acquisition:`}
          carouselItems={[
            {
              number: '20',
              metric: ' hours',
              textLine1: `saved Each Week using our`,
              textLine2: `instant SMS engagement`,
              textLine3: `to contact new applicants.`,
            },
            {
              number: '5',
              metric: ' hours',
              textLine1: `saved Each Week using our`,
              textLine2: `SMS auto-replier`,
              textLine3: `to reply to applicant questions.`,
            },
            {
              number: '25',
              metric: ' hours',
              textLine1: `saved Each Week using our`,
              textLine2: `ATS automations`,
              textLine3: `to log interview outcomes.`,
            },
          ]}
          features={futureProofFeatures}
          backgroundColor={'#000'}
          color={'#fff'}
        />

        {/* Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="#eef"
          color="black"
          title={`Don't be the last to use AI for hiring`}
          subtitle="Screen applicants 75% faster and secure top talent ahead of your competitors."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-blue cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default WhyInstaHearPage;
