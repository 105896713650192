import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeroSection from '../components/sections/HeroSection';
import DemoForm from '../components/forms/DemoForm';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const DemoPage = () => {
  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div>
      <div className="homepage-container">
        {/* Hero section */}
        <HeroSection
          id="hero1"
          background="radial-gradient(circle, rgba(0, 249, 235, 0.1) 25%, white 80%)"
          color="#333"
          title={`Signal Security Hiring Demo`}
          subtitle={`Effortlessly automate 24/7 interviews, connect with top talent, and secure your next great hire—faster than ever before. Experience the future of hiring today.`}
          mobileSubtitle="Effortlessly automate 24/7 interviews, connect with top talent, and secure your next great hire—faster than ever before. Experience the future of hiring today."
          subtitleCustomStyling={{ marginBottom: '.5rem' }}
        />
        <DemoForm
          logoSrc="https://storage.googleapis.com/instahear-react-images/private-demo-company-logos/signal-security-franchise-demo-job-logo.svg?v=2"
          formTitle="Fill out your info below, and we'll text you a link to begin your phone interview."
          initialFirstName=""
          initialLastName=""
          initialPhone=""
          initialEmail=""
          initialJobId={'instahear-demo'}
          jobIds={['instahear-demo']}
          initialAcceptTerms={true}
        />
      </div>
    </div>
  );
};

export default DemoPage;
