import React, { useState } from 'react';
import './FAQSection.css';

const faqData = [
  {
    question: 'What if I already have recruiters?',
    answer: `That’s terrific! InstaHear is best viewed as a recruiter’s assistant. It’s designed to handle the time-consuming, repetitive tasks of the initial hiring stage, such as eliminating the back-and-forth phone tag involved in screening applicants for basic qualifications. This allows your recruiters to focus on engaging the most promising, qualified candidates instead of managing introductory calls. While InstaHear streamlines the early stages of hiring, we still recommend having skilled recruiters to guide applicants through the final steps of the process.`,
  },
  {
    question: 'How does your AI interviewer compare with a human interviewer?',
    answer: `When’s the last time you sent a physical, snail-mail letter? Why were you sending it? Was it to someone you care about? Can you imagine if, instead of email, you had to physically mail each person you needed to communicate with for a given day? Physical mail is slow, and it doesn’t scale. Yet, what makes physical mail magical is the unique human touch: your tone, your writing, your you-ness.\n
    That’s how we think about AI screening. We love humans—there is no replacing them. Our objective is to optimize the aspects of the interview process that can be made more efficient, ensuring that human interactions remain meaningful and seamless. In a world plagued with fake applicants, the top of the talent funnel has never been more overwhelming for talent acquisition teams. Our mission is to bring humans together faster than ever before by screening applicants nearly instantaneously.`,
  },
  {
    question: 'Do applicants know they’re speaking with an AI interviewer?',
    answer: `Yes, our AI interviewer transparently informs applicants at the beginning of the call that they are speaking with an AI assistant. Despite this, over 95% of calls proceed smoothly to completion, demonstrating that applicants are comfortable and engaged with the AI-driven process.`,
  },
  {
    question: 'Can I customize the voice of my AI interviewer?',
    answer: `Yes, you can train the AI to clone the voice of anyone on your team, creating a personalized experience that makes it sound as though the applicant is speaking directly with you.`,
  },
  {
    question: 'Do you offer a free trial?',
    answer: `Yes, we provide a free trial that includes up to 10 phone interviews. This allows you to experience firsthand the quality of our AI interviewer and see how InstaHear can enhance your applicant screening process.`,
  },
  {
    question: 'What if the applicant hangs up during the middle of the call?',
    answer: `If an applicant hangs up, the AI interviewer will immediately call them back to continue the conversation where it left off. If the applicant doesn’t answer, the AI interviewer will leave a voicemail with instructions on how to restart the interview at their convenience.`,
  },
  {
    question:
      'What if an applicant is unavailable to interview or wants to schedule it for a specific time?',
    answer: `Our AI SMS and Email auto-responder informs applicants that they can start their interview at any time that works for them. Clear instructions are provided, including a link to begin the interview when they’re ready.`,
  },
  {
    question: 'Can I use InstaHear only during off-hours and weekends?',
    answer: `Yes, you can customize engagement hours on the Jobs page in your Account Dashboard. However, if an applicant responds to an SMS or Email from InstaHear outside the designated engagement hours, they will still be able to proceed with the AI interviewer—provided the job is active and your account has available credits.`,
  },
  {
    question: 'How does InstaHear integrate with my ATS?',
    answer: `InstaHear sets up two custom automations tailored to your ATS workflow:
Top-of-Funnel Automation: Retrieves new applicants who haven’t been viewed, ensuring no candidates slip through the cracks.
Bottom-of-Funnel Automation: Updates applicant records with key details, including:
- Status changes to “Engaged” or any custom status used by your ATS.
- Outcomes of the interview, such as “Failed Knockouts,” “Failed Interview,” or “Passed Interview.”
These automations are designed to seamlessly integrate with your existing processes while saving time and effort.`,
  },
  {
    question: 'What ATS platforms does InstaHear support?',
    answer: `We can automate with all major Applicant Systems.`,
  },
  {
    question: 'Does InstaHear require API keys for my ATS?',
    answer: `No, InstaHear doesn’t require API keys or special permissions from your ATS. Instead, we build custom automations that mimic the actions of a recruiter logging into your ATS to retrieve and update applicant data. All we need is a standard user account login for your ATS—the same type of account you would create for a recruiter on your team.`,
  },
  {
    question: 'How many times do you engage applicants?',
    answer: `We automatically engage applicants twice: first, immediately after they apply, and then again 24 and 36 hours later if they haven’t yet completed their interview.`,
  },
  {
    question: 'How many questions can I ask applicants during the interview?',
    answer: `There is no default limit to the number of questions your AI interviewer can ask during an phone screen.`,
  },
  {
    question: 'How many knockout questions can I have?',
    answer: `You can include as many knockout questions as you’d like. However, to optimize conversion rates to the phone screen stage, we recommend keeping the number to 7 or fewer, as too many questions can discourage applicants from completing the process.`,
  },
  {
    question:
      'How do you determine whether or not an applicant passes the phone interview?',
    answer: `You can define and manage the Success Criteria in your account dashboard to determine if an applicant is qualified. This allows you to tailor the evaluation process to your specific needs.`,
  },
  {
    question: 'Are the interview calls recorded?',
    answer: `Yes, all calls are recorded and can be listened to your Account dashboard.`,
  },
  {
    question: 'What reporting or metrics do you provide?',
    answer: `We provide you with reports for all of your data, including:
- Exportable reports of all interview activity
- Conversion rates by job
- Speed to interview
- Interview outcome analysis`,
  },
  {
    question:
      'Can I customize the content of the SMS and Email messages that are sent to applicants after the interview?',
    answer: `Yes, you can customize the content of the messages in your account dashboard.`,
  },
  {
    question:
      'Can I be notified when the applicant passes the phone interview?',
    answer: `Yes, you can enable email notifications in your Account Dashboard.`,
  },
  {
    question:
      'How quickly are applicants notified if they Passed or Failed the interview?',
    answer: `Applicants are notified via SMS and Email within 15 minutes of completing their interview, providing clear communication on whether they are moving forward in the hiring process.`,
  },
];

const FAQSection = () => {
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const viewAllFaqs = () => {
    setVisibleFaqs(faqData.length);
  };

  return (
    <div className="faq-section">
      <div className="faq-container">
        {/* Sidebar on Desktop */}
        <div className="faq-sidebar">
          <h2>FAQ</h2>
        </div>

        {/* Accordion List */}
        <div className="faq-accordion">
          {faqData.slice(0, visibleFaqs).map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="faq-toggle">
                  {expandedIndex === index ? '-' : '+'}
                </span>
              </div>
              {expandedIndex === index && (
                <div
                  className="faq-answer"
                  dangerouslySetInnerHTML={{
                    __html: faq.answer.replace(/\n/g, '<br />'),
                  }}
                />
              )}
            </div>
          ))}
          <div className="view-all-btn-container">
            {visibleFaqs < faqData.length && (
              <button className="view-all-btn" onClick={viewAllFaqs}>
                View All
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
