import React, { useEffect } from 'react';
import {
  initGA,
  logPageView,
} from './components/googleAnalytics/googleAnalytics.js';
import { Route, Routes, useLocation } from 'react-router-dom';
import TermsOfService from './marketingPages/TermsOfServicePage.jsx';
import PrivacyPolicy from './marketingPages/PrivacyPolicyPage.jsx';
import ThankYouPage from './appPages/ThankYouPage';
import DemoPage from './marketingPages/DemoPage.jsx'; // Import the new DemoPage
import AdminDemoPage from './appPages/AdminDemoPage';
import './App.css'; // Import the CSS styles
import ApplicationNotFoundPage from './appPages/ApplicationNotFoundPage.jsx';
import JobNotAvailablePage from './appPages/JobNotAvailablePage';
import Homepage from './marketingPages/HomePage.jsx';
import SessionDetailPage from './appPages/SessionDetailPage';
import AdminSessionDetailPage from './appPages/AdminSessionDetailPage';
import CompanyJobSessionsPage from './appPages/CompanyJobSessionsPage';
import LoginPage from './appPages/LoginPage';
import VerifyAuthTokenPage from './appPages/VerifyAuthTokenPage';
import PilotAnalyticsPage from './appPages/JobAnalyticsPage.jsx';
import CompanyJobsPage from './appPages/CompanyJobsPage';
import CompanyAssessmentsPage from './appPages/CompanyAssessmentsPage';
import InterviewNowPage from './appPages/InterviewNowPage';
import AppNavbarWrapper from './appPages/AppNavbarWrapper.jsx'; // Import the wrapper
import CompanyJobDetailsPage from './appPages/CompanyJobDetailsPage';
import CompanyAssessmentDetailsPage from './appPages/CompanyAssessmentDetailsPage';
import LogoutPage from './appPages/Logout';
import { AuthProvider } from './appPages/AuthProvider';
import ProtectedRoutes from './appPages/ProtectedRoutes';
import AdminCompanyJobApplicationsPage from './appPages/AdminCompanyJobApplicationsPage';
import ConversationMessagesPage from './appPages/ConversationMessagesPage';
import BookAMeetingPage from './marketingPages/BookAMeetingPage.jsx';
import DemoSignupPage from './marketingPages/DemoSignupPage.jsx';
import DemoPageSignupSuccessPage from './marketingPages/DemoSignupSuccessPage.jsx';
import AiJobCreatorPage from './appPages/AiJobCreatorPage';
import AdminCompanySettingsPage from './appPages/AdminCompanySettingsPage';
import WhyInstaHearPage from './marketingPages/WhyInstaHearPage';
import HomePageNavbar from './marketingPages/HomePageNavbar.jsx';
import FooterSection from './components/sections/FooterSection.jsx';
import HowItWorksPage from './marketingPages/HowItWorksPage.jsx';
import FeaturesPage from './marketingPages/FeaturesPage.jsx';
import QRCodeGeneratorPage from './marketingPages/QRCodeGeneratorPage.jsx';
import CompanyUsersPage from './appPages/CompanyUsersPage.jsx';

// Layout Component
const Layout = ({ children }) => {
  return (
    <div className="app">
      <HomePageNavbar />
      <div className="content">{children}</div>
      <FooterSection />
    </div>
  );
};

const App = () => {
  const location = useLocation(); // For tracking page views

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]); // Logs page view when route changes
  return (
    <div>
      <Routes>
        {/* Routes with HomePageNavbar and Footer */}
        <Route
          path="/"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        <Route
          path="/signal-security"
          element={
            <Layout>
              <DemoPage />
            </Layout>
          }
        />
        <Route
          path="/book-a-meeting"
          element={
            <Layout>
              <BookAMeetingPage />
            </Layout>
          }
        />
        <Route
          path="/demo-signup"
          element={
            <Layout>
              <DemoSignupPage />
            </Layout>
          }
        />
        <Route
          path="/why-instahear"
          element={
            <Layout>
              <WhyInstaHearPage />
            </Layout>
          }
        />
        <Route
          path="/how-it-works"
          element={
            <Layout>
              <HowItWorksPage />
            </Layout>
          }
        />
        <Route
          path="/features"
          element={
            <Layout>
              <FeaturesPage />
            </Layout>
          }
        />
        <Route
          path="/demo-signup-success"
          element={
            <Layout>
              <DemoPageSignupSuccessPage />
            </Layout>
          }
        />

        {/* Routes without HomePageNavbar and Footer */}
        <Route
          path="/interview/:applicationShortCode"
          element={<InterviewNowPage />}
        />
        <Route
          path="/public/sessions/:sessionId"
          element={<SessionDetailPage />}
        />
        <Route path="/thank-you/jobs/:jobId" element={<ThankYouPage />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/application-not-found"
          element={<ApplicationNotFoundPage />}
        />
        <Route path="/job-not-available" element={<JobNotAvailablePage />} />
        <Route path="/auth/verify" element={<VerifyAuthTokenPage />} />
        <Route path="/logout" element={<LogoutPage />} />

        {/* Authenticated Routes */}
        <Route
          element={
            <AuthProvider>
              {' '}
              <ProtectedRoutes />
            </AuthProvider>
          }
        >
          <Route
            path="/admin/sessions/:sessionId"
            element={
              <AppNavbarWrapper>
                <AdminSessionDetailPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/sessions/jobs/:jobId"
            element={
              <AppNavbarWrapper>
                <CompanyJobSessionsPage />
              </AppNavbarWrapper>
            }
          />

          <Route
            path="/admin/qr-code-generator"
            element={
              <AppNavbarWrapper>
                <QRCodeGeneratorPage />
              </AppNavbarWrapper>
            }
          />

          <Route
            path="/sessions"
            element={
              <AppNavbarWrapper>
                <CompanyJobSessionsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/users"
            element={
              <AppNavbarWrapper>
                <CompanyUsersPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/admin/applications"
            element={
              <AppNavbarWrapper>
                <AdminCompanyJobApplicationsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/admin/applications/jobs/:jobId"
            element={
              <AppNavbarWrapper>
                <AdminCompanyJobApplicationsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/analytics"
            element={
              <AppNavbarWrapper>
                <PilotAnalyticsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/analytics/jobs/:jobId"
            element={
              <AppNavbarWrapper>
                <PilotAnalyticsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/jobs"
            element={
              <AppNavbarWrapper>
                <CompanyJobsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/call-scripts"
            element={
              <AppNavbarWrapper>
                <CompanyAssessmentsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/admin/settings/company"
            element={
              <AppNavbarWrapper>
                <AdminCompanySettingsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/jobs/ai-creator"
            element={
              <AppNavbarWrapper>
                <AiJobCreatorPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/jobs/:jobId/edit"
            element={
              <AppNavbarWrapper>
                <CompanyJobDetailsPage />
              </AppNavbarWrapper>
            }
          />
          <Route
            path="/call-scripts/:assessmentId/edit"
            element={
              <AppNavbarWrapper>
                <CompanyAssessmentDetailsPage mode="edit" />
              </AppNavbarWrapper>
            }
          />

          <Route
            path="/call-scripts/new"
            element={
              <AppNavbarWrapper>
                <CompanyAssessmentDetailsPage mode="create" />
              </AppNavbarWrapper>
            }
          />

          <Route
            path="/admin/demo"
            element={
              <AppNavbarWrapper>
                <AdminDemoPage />
              </AppNavbarWrapper>
            }
          />

          <Route
            path="/conversation/messages/:conversationId"
            element={
              <AppNavbarWrapper>
                <ConversationMessagesPage />
              </AppNavbarWrapper>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
