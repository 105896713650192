import ReactGA from 'react-ga4';

// Retrieve the GA Measurement ID from the environment variables
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const initGA = () => {
  if (measurementId) {
    ReactGA.initialize(measurementId, {
      debug_mode: process.env.REACT_APP_ENV === 'development',
    });
  } else {
    console.error('Google Analytics Measurement ID is missing!');
  }
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};
