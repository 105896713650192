import React from 'react';
import './FooterSection.css';

const FooterSection = () => {
  return (
    <footer className="footer bg-dark text-white py-4" aria-label="Footer">
      <div className="footer-container">
        {/* Logo */}
        <div className="footer-logo">
          <img
            src="https://storage.googleapis.com/instahear-react-images/meta-images-seo/apple-touch-icon.png"
            alt="InstaHear Logo"
            className="footer-logo-img"
          />
        </div>

        {/* Text */}
        <div className="footer-text">
          <p>
            &copy; {new Date().getFullYear()} InstaHear LLC • All Rights
            Reserved
          </p>
        </div>

        {/* Links */}
        <div className="footer-links">
          <a href="/privacy" className="footer-link">
            Privacy
          </a>
          <a href="/terms" className="footer-link">
            Terms
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
