import React from 'react';
import Badge from 'react-bootstrap/Badge';

const FeatureButtonRow = ({ featureButtons }) => {
  return (
    <div className="feature-button-row">
      {featureButtons.map((button, index) => (
        <Badge
          key={index}
          style={{ width: 'fit-content', marginRight: '1rem' }}
          className="my-4 feature-button-badge"
        >
          {button.name}
        </Badge>
      ))}
    </div>
  );
};

export default FeatureButtonRow;
