import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const BACKEND_API_URL =
  process.env.REACT_APP_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://api.instahear.ai';

const QRCodeGenerator = () => {
  const [qrCodes, setQrCodes] = useState([]);
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [destination, setDestination] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const qrCodeRef = useRef(); // Ref for QR code canvas

  // Fetch all QR codes
  const fetchQrCodes = async () => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/admin/qr-codes`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setQrCodes(data);
      } else {
        console.error('Failed to fetch QR codes');
      }
    } catch (error) {
      console.error('Error fetching QR codes:', error);
    }
  };

  useEffect(() => {
    fetchQrCodes();
  }, []);

  // Update or create QR code
  const saveQrCode = async () => {
    if (!code.trim() || !destination.trim()) {
      alert('Code and destination URL are required.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_API_URL}/api/admin/qr-codes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ code, destinationUrl: destination }),
      });

      if (response.ok) {
        alert('QR Code saved successfully');
        fetchQrCodes(); // Refresh the QR code list
        setSelectedQrCode(null);
        setCode('');
        setDestination('');
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message || 'Failed to save QR Code.'}`);
      }
    } catch (error) {
      console.error('Error saving QR Code:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Delete QR code
  const deleteQrCode = async (code) => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/admin/qr-codes/${code}`,
        {
          method: 'DELETE',
          credentials: 'include',
        }
      );

      if (response.ok) {
        alert('QR Code deleted successfully');
        fetchQrCodes(); // Refresh the QR code list
      } else {
        alert('Failed to delete QR Code.');
      }
    } catch (error) {
      console.error('Error deleting QR Code:', error);
    }
  };

  // Handle edit button click
  const editQrCode = (qrCode) => {
    setSelectedQrCode(qrCode);
    setCode(qrCode.code);
    setDestination(qrCode.destinationUrl);
  };

  // Export QR code as PNG
  const exportQrCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    if (canvas) {
      const link = document.createElement('a');
      link.download = `${code || 'qr-code'}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } else {
      alert('QR Code not available to export.');
    }
  };

  return (
    <div className="container my-5">
      <h1>QR Code Management</h1>

      {/* QR Code Table */}
      <table
        style={{
          width: '100%',
          marginBottom: '20px',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th style={{ border: '1px solid #ccc', padding: '10px' }}>Code</th>
            <th style={{ border: '1px solid #ccc', padding: '10px' }}>
              Destination
            </th>
            <th style={{ border: '1px solid #ccc', padding: '10px' }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {qrCodes.map((qrCode) => (
            <tr key={qrCode.code}>
              <td style={{ border: '1px solid #ccc', padding: '10px' }}>
                {qrCode.code}
              </td>
              <td style={{ border: '1px solid #ccc', padding: '10px' }}>
                {qrCode.destinationUrl}
              </td>
              <td style={{ border: '1px solid #ccc', padding: '10px' }}>
                <button
                  onClick={() => editQrCode(qrCode)}
                  style={{
                    marginRight: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </button>
                <button
                  onClick={() => deleteQrCode(qrCode.code)}
                  style={{
                    padding: '5px 10px',
                    backgroundColor: '#FF0000',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* QR Code Generator */}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="code" style={{ fontSize: '16px', fontWeight: 'bold' }}>
          QR Code Identifier:
        </label>
        <input
          id="code"
          type="text"
          placeholder="Enter QR code identifier"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{
            display: 'block',
            margin: '10px 0',
            padding: '10px',
            width: '100%',
            maxWidth: '400px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
        <label
          htmlFor="destination"
          style={{ fontSize: '16px', fontWeight: 'bold' }}
        >
          Destination URL:
        </label>
        <input
          id="destination"
          type="url"
          placeholder="Enter destination URL"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          style={{
            display: 'block',
            margin: '10px 0',
            padding: '10px',
            width: '100%',
            maxWidth: '400px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
        <button
          onClick={saveQrCode}
          disabled={loading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: loading ? '#888' : '#007BFF',
            border: 'none',
            borderRadius: '4px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading
            ? 'Saving...'
            : selectedQrCode
            ? 'Update QR Code'
            : 'Create QR Code'}
        </button>
      </div>

      {/* QR Code Display */}
      {code && (
        <div style={{ marginTop: '20px', textAlign: 'center' }} ref={qrCodeRef}>
          <QRCodeCanvas
            value={`${BACKEND_API_URL}/api/redirects/qr-code/${code}`}
            size={512}
          />
          <p style={{ marginTop: '10px', fontSize: '16px', color: '#555' }}>
            QR Code URL:{' '}
            <strong>{`${BACKEND_API_URL}/api/redirects/qr-code/${code}`}</strong>
          </p>
          <button
            onClick={exportQrCode}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Export as PNG
          </button>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
