import React, { useEffect, useState, useCallback } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';
import {
  Table,
  Badge,
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import AppPageTitle from '../components/app/AppPageTitle';
import 'bootstrap/dist/css/bootstrap.min.css';
import debounce from 'lodash.debounce';
import './JobSessionsPage.css';
import { PersonRolodex } from 'react-bootstrap-icons'; // Import the PersonRolodex icon

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const pageSize = 15;

const CompanyJobSessionsPage = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sessions, setSessions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [searchInput, setSearchInput] = useState(
    searchParams.get('searchText') || ''
  );
  const [searchText, setSearchText] = useState(
    searchParams.get('searchText') || ''
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page')) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  // Default result filter
  const defaultResultFilter = () => {
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    return {
      KNOCKOUT_FAILED: true,
      PASSED: true,
      FAILED: true,
      INCOMPLETE: isAdmin,
      IN_PROGRESS: isAdmin,
    };
  };

  // Parse the `resultFilter` query param or initialize default
  const [resultFilter, setResultFilter] = useState(() => {
    const queryFilter = searchParams.get('resultFilter');
    if (queryFilter) {
      try {
        return JSON.parse(queryFilter);
      } catch (error) {
        console.error('Error parsing resultFilter:', error);
        return defaultResultFilter();
      }
    }
    return defaultResultFilter();
  });

  // Update searchParams when filters, search, or page change
  useEffect(() => {
    const updatedParams = {
      ...(searchText && { searchText }),
      ...(currentPage > 1 && { page: currentPage }),
      ...(Object.keys(resultFilter).length > 0 && {
        resultFilter: JSON.stringify(resultFilter),
      }),
    };
    setSearchParams(updatedParams);
  }, [searchText, currentPage, resultFilter, setSearchParams]);

  // Fetch sessions when filters, search, or page change
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const apiEndpoint = `${BACKEND_API_URL}/api/sessions/paginated`;

        const filters = {
          searchText,
          jobId,
          resultFilter: Object.keys(resultFilter).filter(
            (key) => resultFilter[key] === true
          ),
          sortOrder,
          page: currentPage,
          pageSize,
        };

        const response = await fetch(apiEndpoint, {
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({ filters }),
        });

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (response.status === 403) {
          navigate('/sessions');
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch sessions data');
        }

        const data = await response.json();
        setIsLoading(false);
        setSessions(data.sessions);
        setIsAdmin(data.isAdmin);
        setJobTitle(data.job?.title || 'All Sessions');
        setCurrentPage(data.pagination.page || 1);
        setTotalPages(data.pagination.totalPages || 1);
      } catch (error) {
        console.error('Error fetching sessions data:', error);
        navigate('/login');
      }
    };

    fetchSessions();
    const getTimezoneAbbreviation = () => {
      const date = new Date();
      const timeString = date.toLocaleTimeString('en-us', {
        timeZoneName: 'short',
      });
      const abbreviationMatch = timeString.split(' ').pop();
      setTimezoneAbbreviation(abbreviationMatch || '');
    };

    getTimezoneAbbreviation();
  }, [jobId, navigate, searchText, resultFilter, sortOrder, currentPage]);

  // Debounced search input
  const updateSearchText = debounce((value) => {
    if (value.length > 1) {
      setSearchText(value);
    } else {
      setSearchText('');
    }
  }, 300);

  useEffect(() => {
    updateSearchText(searchInput);
    return () => {
      updateSearchText.cancel();
    };
  }, [searchInput, updateSearchText]);

  const handlePageChange = useCallback((direction) => {
    setCurrentPage((prevPage) =>
      direction === 'next' ? prevPage + 1 : Math.max(prevPage - 1, 1)
    );
  }, []);

  const renderPagination = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <Button
        variant="secondary"
        size="sm"
        disabled={currentPage === 1}
        onClick={() => handlePageChange('prev')}
      >
        Previous
      </Button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        variant="secondary"
        size="sm"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange('next')}
      >
        Next
      </Button>
    </div>
  );

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };

  const formatDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
  };

  const handleBadgeClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleIconClick = (content) => {
    console.log(content);
    setModalContent(content);
    setShowModal(true);
  };

  const renderModalContent = (content) => {
    return content.split('\n').map((line, index) => {
      const boldText = [
        'Q:',
        'Failed Criteria:',
        'Call Ended Reason:',
        'Incomplete Criteria:',
      ];
      const isBold = boldText.some((keyword) =>
        line?.trim().startsWith(keyword)
      );
      return (
        <div
          key={index}
          style={{
            fontWeight: isBold ? 'bold' : 'normal',
            marginBottom: '5px',
          }}
        >
          {line}
        </div>
      );
    });
  };

  const renderContactCard = (session) => {
    // Prepare the contact details for the modal
    const contactDetails = [
      `${session.phone || 'N/A'}`,
      `${session.email || 'N/A'}`,
    ].join('\n\n\n\n');

    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => handleIconClick(contactDetails)}
      >
        <PersonRolodex style={{ color: 'inherit', fontSize: '1rem' }} />
      </div>
    );
  };

  const renderBadge = (
    result,
    failedKnockouts,
    callEndedReason,
    failedReasons,
    incompleteReasons
  ) => {
    if (result === 'KNOCKOUT_FAILED') {
      const incorrectAnswers = failedKnockouts
        .filter((answer) => answer.answer !== answer.correctAnswer)
        .map(
          (incorrect, i) =>
            `Q: ${incorrect.question}\nApplicant Answer: ${incorrect.answer}\nCorrect Answer: ${incorrect.correctAnswer}`
        )
        .join('\n\n');

      return (
        <Badge
          bg="warning"
          style={{ cursor: 'pointer' }}
          onClick={() => handleBadgeClick(incorrectAnswers)}
        >
          KNOCKOUT FAILED
        </Badge>
      );
    } else if (result === 'FAILED') {
      const failedReasonsList = failedReasons?.failedSuccessCriteria
        ? `Failed Criteria:\n\n${failedReasons.failedSuccessCriteria.join(
            '\n'
          )}`
        : 'No additional information provided';
      return (
        <Badge
          bg="danger"
          style={{ cursor: 'pointer' }}
          onClick={() => handleBadgeClick(failedReasonsList)}
        >
          FAILED
        </Badge>
      );
    } else if (result === 'INCOMPLETE') {
      const incompleteReasonsList = incompleteReasons?.omittedSuccessCriteria
        ? incompleteReasons.omittedSuccessCriteria.join('\n')
        : 'No additional information provided';
      return (
        <Badge
          bg="secondary"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleBadgeClick(
              `Call Ended Reason:\n${callEndedReason}\n\n\nIncomplete Criteria:\n\n${incompleteReasonsList}`
            )
          }
        >
          INCOMPLETE
        </Badge>
      );
    }

    switch (result) {
      case 'PASSED':
        return <Badge bg="success">PASSED</Badge>;
      case 'IN_PROGRESS':
        return <Badge bg="primary">IN PROGRESS</Badge>;
      default:
        return <Badge bg="light">UNKNOWN</Badge>;
    }
  };

  return (
    <Container fluid className="wide-container small-font">
      <AppPageTitle
        preTitle={jobId ? 'Sessions for: ' : ''}
        title={jobId ? jobTitle : 'Sessions'}
      />
      {!isLoading && (
        <div>
          <Row className="mb-3">
            <Col xs={12} md={4} className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search for applicant..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-100"
              />
              {searchInput && (
                <button
                  type="button"
                  onClick={() => setSearchInput('')}
                  className="position-absolute top-50 end-0 translate-middle-y me-3"
                  aria-label="Clear"
                  style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '1.25rem',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '1.5rem',
                    height: '1.5rem',
                    lineHeight: '1',
                  }}
                >
                  &times;
                </button>
              )}
            </Col>
            <Col xs={12} md={8} className="d-flex flex-wrap align-items-center">
              {Object.keys(resultFilter).map((key) => (
                <Form.Check
                  inline
                  key={key}
                  label={key.replace('_', ' ')}
                  name={key}
                  type="checkbox"
                  checked={resultFilter[key]}
                  onChange={(e) => {
                    setResultFilter({
                      ...resultFilter,
                      [e.target.name]: e.target.checked,
                    });
                    setCurrentPage(1);
                  }}
                  className="me-2"
                />
              ))}
            </Col>
          </Row>

          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="sticky-header"
          >
            <thead className="job-sessions-page-thead">
              <tr>
                <th onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
                  Created At ({timezoneAbbreviation}){' '}
                  {sortOrder === 'ASC' ? '↑' : '↓'}
                </th>
                <th className="job-sessions-page-td-width">Applicant Name</th>
                <th>Contact</th>
                <th className="job-sessions-page-td-width">Job Group</th>
                <th className="job-sessions-page-td-width">Job</th>
                <th>Result</th>
                <th>Scorecard</th>
                <th>Recording</th>
                <th>Duration</th>
                {isAdmin && <th>Admin</th>}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session, index) => (
                <tr key={index}>
                  <td>{formatDateTime(session.createdAt)}</td>
                  <td className="job-sessions-page-td-width">{`${session.firstName} ${session.lastName}`}</td>
                  <td>{renderContactCard(session)}</td>
                  <td className="job-sessions-page-td-width">
                    {session.jobGroupName}
                  </td>
                  <td className="job-sessions-page-td-width">
                    <Link to={`/jobs/${session.internalJobId}/edit`}>
                      {session.jobTitle}
                    </Link>
                  </td>
                  <td>
                    {renderBadge(
                      session.result,
                      session.failedKnockouts,
                      session.callEndedReason,
                      session.failedReasons,
                      session.incompleteReasons
                    )}
                  </td>
                  <td>
                    {session.sessionUrl && (
                      <a href={session.sessionUrl} rel="noopener noreferrer">
                        View
                      </a>
                    )}
                  </td>
                  <td>
                    {session.recordingUrl && (
                      <a
                        href={session.recordingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Listen
                      </a>
                    )}
                  </td>
                  <td>
                    {session.duration ? (
                      formatDuration(session.duration)
                    ) : (
                      <span></span>
                    )}
                  </td>
                  {isAdmin && (
                    <td>
                      {session.manageUrl && (
                        <a href={session.manageUrl} rel="noopener noreferrer">
                          Manage
                        </a>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderModalContent(modalContent)}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {renderPagination()}
        </div>
      )}
      {!isLoading && sessions && sessions.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center mt-4">No sessions found.</h3>
          <h4 className="mt-4">
            Double-check that your Jobs are enabled and published.
          </h4>
        </div>
      )}
    </Container>
  );
};

export default CompanyJobSessionsPage;
