import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Spinner, Button, Card, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CompanyJobDetailsPage.css'; // Import specific CSS for this page
import AppPageTitle from '../components/app/AppPageTitle';
import RequiredAsterisk from './RequiredAsterisk';
import InfoTooltip from './InfoTooltip';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const AdminCompanySettingsPage = () => {
  const [aiAssessmentCreatorPrompt, setAiAssessmentCreatorPrompt] =
    useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanySettings = async () => {
      try {
        const response = await fetch(
          `${BACKEND_API_URL}/api/admin/company-settings`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch company settings.');
        }
        const data = await response.json();
        setAiAssessmentCreatorPrompt(data.aiAssessmentCreatorPrompt || '');
      } catch (error) {
        toast.error('Failed to load company settings. Please try again.', {
          position: 'top-right',
          autoClose: 5000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanySettings();
  }, []);

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/admin/company-settings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ aiAssessmentCreatorPrompt }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save company settings.');
      }

      toast.success('Company settings saved successfully.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } catch (error) {
      toast.error('Failed to save company settings. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleResetSettings = async () => {
    if (
      !window.confirm(
        'Confirm reset your Company settings to the platform defaults?'
      )
    ) {
      return;
    }

    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/admin/company-settings/reset`,
        {
          method: 'POST',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to reset company settings.');
      }

      const data = await response.json();
      setAiAssessmentCreatorPrompt(data.aiAssessmentCreatorPrompt || '');

      toast.success('Company settings have been reset to defaults.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } catch (error) {
      toast.error('Failed to reset company settings. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="mt-5" style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <AppPageTitle title="Company Settings" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleSaveSettings}
            disabled={isSaving}
            className="btn btn-primary fw-bold"
            style={{ marginRight: '10px' }}
          >
            {isSaving ? (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                  className="me-2"
                  role="status"
                />
                Saving...
              </>
            ) : (
              'Publish Settings'
            )}
          </Button>
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="actionDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: 'transparent',
                padding: '5px 10px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              &#8942; {/* Vertical ellipsis icon */}
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="actionDropdown"
            >
              <li>
                <button className="dropdown-item" onClick={handleResetSettings}>
                  Reset
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="ai-job-creator" className="mb-3 small-font">
        <Tab
          eventKey="ai-job-creator"
          title={<span style={{ fontWeight: 'bold' }}>AI Job Creator</span>}
        >
          <Card className="mb-4 card">
            <Card.Body className="p-4 small-font card-body">
              {isLoading ? (
                <div className="text-center my-5">
                  <Spinner animation="border" role="status" />
                  <span className="ms-2">Loading...</span>
                </div>
              ) : (
                <Form>
                  <Form.Group controlId="markdownEditor">
                    <Form.Label>
                      Call Script Generator Prompt <RequiredAsterisk />
                      <InfoTooltip message="Enter the AI prompt for generating job questions." />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={30}
                      value={aiAssessmentCreatorPrompt}
                      onChange={(e) =>
                        setAiAssessmentCreatorPrompt(e.target.value)
                      }
                      placeholder="Enter the AI Assessment Creator Prompt here"
                      style={{
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'monospace',
                        fontSize: '0.80rem'
                      }}
                    />
                  </Form.Group>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminCompanySettingsPage;
